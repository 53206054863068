import React, { Fragment } from 'react'

import classes from './HelloSvg.module.css'

const helloSvg = () => (
    <Fragment>
        <svg version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
	         viewBox="0 0 408.7 197.3"
             className={classes.SVG}>
            <path className={classes.AnimatedUnderline} d="M106,89.8c0.8-4.4,1.4-8.8,1.7-13.1c0.3-4.4,0.1-8.4-0.4-12.1 M135.8,141c0-3.5,0.3-7.4,0.9-12
                c0.6-4.5,1.5-9.7,2.8-15.4c1.3-5.8,2.9-12.3,5.2-19.6c0.2-0.5,0.3-1.1,0.5-1.6c6-4,12.1-8.3,18.6-13c6.6-4.9,12.4-11.4,18.5-18.3
                c4.1-4.6,7.2-9.1,9.3-13.7c2.1-4.5,2.9-8.6,2.4-12.3 M201.4,139.2c9.6-9.1,14.2-14.4,17.9-19.4c1.9-2.6,3.5-5.3,4.5-8.1
                c1-2.8,1.3-5.7,0.8-8.7 M248.1,131.6c-0.2-1.6-0.5-3.4-0.8-5.4c-0.3-1.9-0.4-3.9-0.3-6.1c0.9-1.3,1.8-2.4,2.5-3.5
                c0.8-1.1,1.7-2.1,2.7-3.4c1-1.2,2.1-2.7,3.3-4.4c1.2-1.7,2.8-3.9,4.6-6.6c1.8-2.7,3.9-6,6.4-9.9c2.5-3.9,5.3-8.6,8.7-14.2
                c1.7-2.8,3.3-5.8,4.9-9c1.6-3.2,3.2-6.5,4.6-9.9c1.4-3.4,2.7-6.8,3.9-10.1s2.2-6.6,2.9-9.6s1.4-5.8,1.7-8.3c0.3-2.5,0.4-4.7,0.1-6.4
                M295.6,124.6c-0.2-1.6-0.5-3.4-0.8-5.4c-0.3-1.7-0.4-3.5-0.3-5.4c9.7-13.5,18.4-26.7,19.5-28.5c2.5-3.9,5.3-8.6,8.7-14.2
                c1.7-2.8,3.3-5.8,4.9-9c1.6-3.2,3.2-6.5,4.6-9.9c1.4-3.4,2.7-6.8,3.9-10.1c1.2-3.4,2.2-6.6,2.9-9.6c0.8-3,1.4-5.8,1.7-8.3
                c0.3-2.5,0.4-4.7,0.1-6.4 M341.1,111.4c1-4.4,2.4-8.8,4.4-13c1.9-4.3,4.2-8.2,6.8-12 M384.6,94.9c0.2-2.1,0.3-4.2,0.3-6.3
                c0-2.1-0.2-4-0.4-5.6c-0.3-2.3-0.9-4.3-1.6-6.2 M333.3,129.9c1.2,2.1,2.6,3.9,4.4,5.3s3.8,2.4,6,3c2.2,0.6,4.6,0.7,7.1,0.3
                c2.4-0.4,5-1.2,7.7-2.4c2.7-1.2,5.3-3,8-5.2c2.6-2.2,5.1-5,7.4-8.2c2.3-3.3,4.4-7.1,6.2-11.5c0,0,0,0,0,0c1.2,0.1,2.2,0,3.2-0.1
                c3.2-0.5,6.1-1.7,8.8-3.6c2.6-1.9,5-5,7.2-9.3 M289.3,131.8c1.4,2.8,3.1,5,5,6.7c1.9,1.7,3.9,2.8,6.2,3.3c2.2,0.6,4.5,0.7,6.8,0.3
                c2.8-0.4,5.4-1.5,7.8-3.3c2.5-1.8,4.8-4,6.9-6.5c2.1-2.5,4.4-6.2,6.1-9 M241.8,138.9c1.4,2.8,3.1,5,5,6.7s3.9,2.8,6.2,3.3
                c2.2,0.6,4.5,0.7,6.8,0.3c2.8-0.4,6.7-2.5,9.1-4.4c2.4-1.9,8.1-7.6,16.8-18.9 M136.9,165.9c2.5,2.2,5.5,3.7,9,4.6
                c3.5,0.9,7.5,1,12,0.3c4.3-0.6,8.1-2.2,11.7-4.6c3.2-2.1,7.3-5.3,12.1-9.3c1.2,1.1,2.5,2,3.9,2.7c2.5,1.2,5.2,2,8,2.3
                c2.8,0.3,5.4,0.2,7.8-0.1c3.3-0.5,9.8-2.5,18.1-9.9c5.1-4.5,9.8-10.2,16.6-18.4 M14.1,187.7c1.4,1.2,3.2,2.1,5.4,2.8s5,0.7,8.3,0.2
                c3.7-0.5,7.6-1.9,11.7-4c4.1-2.1,8.2-4.8,12.4-8.1c4.2-3.3,8.3-7.1,12.5-11.5c4.1-4.3,8.1-9,11.9-14c3.8-5,7.3-10.3,10.7-15.8
                c3.3-5.5,6.6-11,8.8-16.7c10.9-5.3,19.7-10.7,24.7-13.9 M21.1,84.4c7.1-7.9,11.4-12.2,16.8-17.4c3.7-3.5,6.9-6.5,10.8-9.3
                c3.9-2.8,8.1-5.1,12.7-7.1c4.6-2,9.6-3.4,14.9-4.1c2.9-0.4,5.5-0.4,8,0.2c2.4,0.6,4.6,1.5,6.4,2.9 M156.8,61.1
                c1.9-4,3.8-7.9,5.9-11.4c2-3.6,4.2-6.7,6.3-9.4c2.2-2.7,4.4-4.9,6.7-6.6c2.3-1.7,4.5-2.7,6.9-3 M22.7,169.2c1.7-3.3,4.1-6.5,7.3-9.7
                c3.1-3.2,6.8-6.3,11-9.3c4.2-3,8.5-5.8,13-8.5s8.8-5.1,13.2-7.3 M189,121.7c2.1-4.6,4.5-8.7,7.1-12.3c2.6-3.6,5.4-6.5,8.2-8.7
                M250.1,92.6c1.5-6.8,3.4-13.4,5.7-19.9c2.3-6.5,4.8-12.7,7.6-18.5c2.8-5.8,5.6-11,8.3-15.4c2.8-4.5,5.4-8.1,8-10.8 M297.6,85.6
                c1.5-6.8,3.4-13.4,5.7-19.9c2.3-6.5,4.8-12.7,7.6-18.5c2.8-5.8,5.6-11,8.3-15.4c2.8-4.5,5.4-8.1,7.9-10.8 M367,87.7
                c-0.3-1.8-0.3-3.6-0.2-5.3c0.1-1.7,0.4-3.3,0.9-4.6c0.5-1.4,1.2-2.5,2-3.4c0.8-0.9,1.8-1.4,3-1.6"/>
            <path className={classes.AnimatedLetters} d="M18.6,76.9c0,0-2.1-5.2-0.5-8.4c1.6-3.2,5.2-6.6,13.9-13.6c4.2-3.3,8.3-6.7,12.8-9.5c4.6-2.8,9.3-5.1,14.1-7
                c4.8-1.9,9.5-3.1,14-3.8c4.1-0.6,8-0.6,11.5,0c3.6,0.6,6.8,1.9,9.6,3.7c2.8,1.9,5.1,4.4,7,7.5c1.9,3.1,3.1,6.9,3.8,11.3
                c0.5,3.7,0.7,7.8,0.4,12.1c-0.3,4.4-0.8,8.7-1.7,13.1c-0.9,4.4-1.9,8.7-3.2,12.8s-2.7,7.9-4.2,11.3c-0.8,2.1-1.8,4.3-2.8,6.7
                c-2.3,5.7-5.5,11.2-8.8,16.7c-3.3,5.5-6.9,10.8-10.7,15.8c-3.8,5-7.7,9.7-11.9,14c-4.1,4.3-8.3,8.2-12.5,11.5
                c-4.2,3.3-8.3,6-12.4,8.1c-4.1,2.1-8,3.4-11.7,4c-3.3,0.5-6.1,0.4-8.3-0.2s-4-1.6-5.4-2.8c-1.4-1.2-2.4-2.6-3.1-4.2
                c-0.7-1.6-1.1-3-1.3-4.4c-0.2-1.7-0.1-3.6,0.4-5.8c0.5-2.2,1.5-4.5,2.8-6.9c1.4-2.4,3.2-4.8,5.5-7.2c2.3-2.4,5-4.8,8.3-7.1
                c5-3.6,10-6.8,15-9.8c5-3,10-5.8,15-8.4c5-2.6,10-5.1,14.9-7.4c55.9-25.8,83.9-46.4,91.4-52.6c5.5-4.6,10.7-10.1,16-16
                c2.2-2.4,4-4.7,5.4-6.9c1.4-2.2,2.5-4.3,3.4-6.2c0.8-1.9,1.3-3.6,1.6-5.2c0.3-1.6,0.3-2.9,0.1-4c-0.2-1.6-0.9-2.9-2.1-3.9
                c-1.2-1-2.8-1.3-4.9-1c-2.3,0.3-4.6,1.4-6.9,3c-2.3,1.7-4.5,3.9-6.7,6.6c-2.2,2.7-4.3,5.8-6.3,9.4c-2,3.6-4,7.4-5.9,11.4
                c-1.9,4-3.7,8.3-5.3,12.7c-1.7,4.4-3.2,8.8-4.7,13.1c-1,3.2-1.5,5.1-2.2,7.1c-2.3,7.3-3.9,13.8-5.2,19.6c-1.3,5.8-2.2,10.9-2.8,15.4
                c-0.6,4.5-0.9,8.5-0.9,12c0,3.5,0.2,6.5,0.6,9.1c0.5,3.3,1.3,6,2.6,8.3c1.3,2.3,2.8,4.1,4.6,5.4c1.8,1.3,3.9,2.2,6.2,2.7
                c2.3,0.4,4.8,0.5,7.4,0.1c3.5-0.5,6.7-1.8,9.6-3.7c6.4-4.2,20.5-16.2,31.8-28.4c9.2-10,13.4-15.2,15.1-18.5s2.8-6.3,3.2-9
                s0.4-4.3,0.2-5.8s-0.8-2.7-1.8-3.5c-1-0.8-2-1.1-3.1-0.9c-2.5,0.4-5.1,1.7-8,3.9c-2.8,2.2-5.6,5.1-8.2,8.7c-2.6,3.6-5,7.7-7.1,12.3
                c-2.1,4.6-3.7,9.5-4.6,14.6c-0.2,1.7-0.2,3.1-0.1,3.7c0,0.7,0,1.4,0,2.1c0,0.7,0.1,1.4,0.2,2c0.3,2,0.9,3.9,1.7,5.7
                c0.9,1.7,2,3.2,3.3,4.4c1.4,1.2,3,2.1,4.8,2.6c1.8,0.5,3.9,0.6,6.1,0.3c7.4-1.1,12.2-3.5,20.9-11.8c8.8-8.5,23.5-29.1,27.7-35.2
                c3.5-5,6.9-10,10.2-15c3.7-5.8,7.2-11.4,10.4-17c3.2-5.6,6.1-10.9,8.6-16.1c2.6-5.2,4.7-10,6.4-14.5c1.7-4.5,2.9-8.6,3.7-12.2
                s1-6.7,0.6-9.2c-0.1-0.5-0.4-1-0.9-1.7c-0.6-0.7-1.2-1-1.8-0.9c-1.7,0.3-3.9,1.8-6.4,4.5c-2.5,2.7-5.2,6.3-8,10.8
                c-2.8,4.5-5.5,9.6-8.3,15.4c-2.8,5.8-5.3,12-7.6,18.5c-2.3,6.5-4.2,13.1-5.7,19.9c-1.5,6.8-2.4,13.3-2.7,19.7l-0.4,7.7
                c-0.1,2.2,0,4.3,0.3,6.2c0.3,1.9,0.6,3.7,0.8,5.4c0.7,4.9,2,8.4,4,10.6c1.9,2.1,4.3,3,7.2,2.6c1.9-0.3,4.2-1.1,7.1-3.3
                c2.9-2.2,8.4-8.4,16.7-19.2c11.7-15.3,23.5-34.1,23.5-34.1c3.7-5.8,7.2-11.4,10.4-17c3.2-5.6,6.1-10.9,8.6-16.1
                c2.6-5.2,4.7-10,6.4-14.5c1.7-4.5,2.9-8.6,3.7-12.2s1-6.7,0.6-9.2c-0.1-0.5-0.4-1-0.9-1.7c-0.6-0.7-1.2-1-1.8-0.9
                c-1.7,0.3-3.9,1.8-6.4,4.5c-2.5,2.7-5.2,6.3-7.9,10.8c-2.8,4.5-5.5,9.6-8.3,15.4c-2.8,5.8-5.3,12-7.6,18.5
                c-2.3,6.5-4.2,13.1-5.7,19.9c-1.5,6.8-2.4,13.3-2.7,19.7l-0.4,7.7c-0.1,2.2,0,4.3,0.3,6.2c0.3,1.9,0.6,3.7,0.8,5.4
                c0.7,4.9,2,8.4,4,10.6c1.9,2.1,4.3,3,7.2,2.6c1.9-0.3,3.8-1.1,5.6-2.3c1.8-1.3,3.5-2.8,5.2-4.7c1.7-1.9,3.3-3.9,4.8-6.2
                c1.5-2.3,2.9-4.5,4.2-6.8s2.5-4.5,3.5-6.6l3.3-6.6c1.1-3.1,2.5-6.1,4.2-9.1c1.7-3,3.6-5.8,5.7-8.5c2.1-2.7,4.3-5.1,6.6-7.4
                c2.3-2.2,4.7-4.1,7.1-5.7l3.2,3.2c-2.7,2.6-5.3,5.7-7.9,9.5c-2.6,3.7-4.9,7.7-6.8,12c-1.9,4.3-3.4,8.6-4.4,13
                c-1,4.4-1.2,8.6-0.6,12.6c0.5,3.5,1.7,6.2,3.6,7.9c1.9,1.7,4.4,2.3,7.6,1.9c2.5-0.4,4.9-1.2,7.1-2.6c2.3-1.4,4.4-3.1,6.5-5.2
                s4-4.5,5.8-7.2c1.8-2.7,3.4-5.6,5-8.5c0.9-2,1.6-3.9,1.6-3.9c0.7-2.1,1.3-4.3,1.7-6.4c0.4-2.2,0.7-4.2,0.9-6.2
                c0.2-2,0.3-3.8,0.2-5.5c0-1.7-0.2-3.2-0.3-4.4c-0.2-1.7-0.6-3.2-1.1-4.6c-0.5-1.4-1.1-2.5-1.7-3.5c-0.6-1-1.3-1.7-2.1-2.2
                c-0.8-0.5-1.6-0.7-2.5-0.5c-1.1,0.2-2.1,0.7-3,1.6c-0.8,0.9-1.5,2-2,3.4c-0.5,1.4-0.8,2.9-0.9,4.6c-0.1,1.7,0,3.5,0.2,5.3
                c0.2,1.6,0.6,3.4,1.2,5.3c0.6,1.9,1.3,3.7,2.2,5.4c0.9,1.7,1.9,3.3,3.1,4.7c3.6,4.2,6.8,4.1,9.6,3.9c1.6-0.2,3-0.6,4.2-1.1
                c1.2-0.5,2.4-1.2,3.4-2c1-0.8,1.9-1.9,2.8-3.1c0.9-1.2,1.6-2.7,2.4-4.5l3.4,1.9c-2.1,4.2-4.5,7.3-7.2,9.3c-2.7,1.9-5.6,3.2-8.8,3.6
                c-2.8,0.3-6.8,0.6-12.1-4.1c-1.5-1.4-2.8-3.1-3.9-5c-1.1-1.9-2.1-4.1-2.9-6.4c-0.8-2.3-1.3-4.7-1.7-7.2c-0.4-2.5-0.4-4.9-0.1-7.2
                c0.3-2.3,0.9-4.3,1.7-6.2c0.9-1.8,1.9-3.3,3.3-4.5c1.3-1.2,2.9-1.9,4.6-2.1c1.6-0.2,3,0,4.4,0.6c1.4,0.6,2.6,1.6,3.6,2.9
                c1.1,1.3,2,2.9,2.7,4.8s1.3,4,1.6,6.2c0.2,1.7,0.4,3.6,0.4,5.6c0,2.1-0.1,4.2-0.3,6.3c-0.2,2.1-0.6,4.3-1,6.4
                c-0.5,2.1-1.1,4-1.9,5.8c0,0-0.8,2.1-1.6,4.1c-1.8,4.4-3.9,8.2-6.2,11.5c-2.3,3.3-4.8,6-7.4,8.2c-2.6,2.2-5.3,4-8,5.2
                c-2.7,1.2-5.3,2-7.7,2.4c-2.5,0.4-4.8,0.3-7.1-0.3c-2.2-0.6-4.2-1.5-6-3s-3.2-3.2-4.4-5.3c-1.2-2.1-2-4.6-2.4-7.4l-0.3-3
                c-1.7,2.8-6.5,10.3-8.6,12.8c-2.1,2.5-4.4,4.7-6.9,6.5c-2.5,1.8-5.1,2.9-7.8,3.3c-2.3,0.3-4.6,0.2-6.8-0.3c-2.2-0.6-4.3-1.7-6.2-3.3
                c-1.9-1.7-3.5-3.9-5-6.7c-1.4-2.8-2.4-6.2-3-10.3c-0.8-5.1-0.9-10.6-0.6-16.4s1.2-11.7,2.5-17.6c1.2-6,2.9-11.9,4.9-17.9
                c2-5.9,4.2-11.6,6.7-17.1c2.4-5.5,5.1-10.6,7.9-15.3s5.7-8.9,8.6-12.5c2.9-3.6,5.8-6.5,8.6-8.7c2.8-2.2,5.5-3.5,8.1-3.9
                c2-0.3,3.7-0.1,5.2,0.7c1.5,0.8,2.4,2.4,2.8,4.9c0.3,1.7,0.2,3.9-0.1,6.4c-0.3,2.5-0.9,5.3-1.7,8.3c-0.8,3-1.8,6.2-2.9,9.6
                c-1.2,3.4-2.5,6.7-3.9,10.1c-1.4,3.4-3,6.7-4.6,9.9c-1.6,3.2-3.3,6.2-4.9,9c-3.3,5.6-6.2,10.4-8.7,14.2c-1.5,2.3-15.8,24-28.4,40.5
                c-8.7,11.3-14.3,17-16.8,18.9c-2.4,1.9-6.3,4-9.1,4.4c-2.3,0.3-4.6,0.2-6.8-0.3c-2.2-0.6-4.3-1.7-6.2-3.3s-3.5-3.9-5-6.7
                c-1.4-2.8-2.4-6.2-3-10.3c-0.8-5.1-0.9-10.6-0.6-16.4s1.2-11.7,2.5-17.6c1.2-6,2.9-11.9,4.9-17.9c2-5.9,4.2-11.6,6.7-17.1
                c2.4-5.5,5.1-10.6,7.9-15.3c2.8-4.8,5.7-8.9,8.6-12.5c2.9-3.6,5.8-6.5,8.6-8.7s5.5-3.5,8.1-3.9c2-0.3,3.7-0.1,5.2,0.7
                c1.5,0.8,2.4,2.4,2.8,4.9c0.3,1.7,0.2,3.9-0.1,6.4c-0.3,2.5-0.9,5.3-1.7,8.3s-1.8,6.2-2.9,9.6s-2.5,6.7-3.9,10.1
                c-1.4,3.4-3,6.7-4.6,9.9c-1.6,3.2-3.3,6.2-4.9,9c-3.3,5.6-6.2,10.4-8.7,14.2c-2.5,3.9-4.6,7.2-6.4,9.9c-1.8,2.7-3.3,4.9-4.6,6.6
                c-1.2,1.7-2.4,3.2-3.3,4.4c-1,1.2-1.9,2.3-2.7,3.4c-6.5,9.2-21.8,27.9-30,35.3s-14.7,9.4-18.1,9.9c-2.4,0.4-5,0.4-7.8,0.1
                c-2.8-0.3-5.5-1-8-2.3c-2.5-1.2-4.7-3.1-6.6-5.5c-1.9-2.4-3.1-5.6-3.7-9.6c-0.6-4.2-0.5-8.4,0.4-12.5c0.9-4.1,2.2-8.1,4-11.8
                c1.8-3.7,4-7.2,6.6-10.4c2.6-3.2,5.3-6.1,8-8.5c2.8-2.4,5.6-4.4,8.3-5.9c2.8-1.5,5.3-2.5,7.5-2.8c1.5-0.2,3-0.2,4.6,0
                c1.6,0.2,3.1,0.7,4.4,1.5c1.4,0.8,2.5,1.9,3.5,3.4c1,1.5,1.7,3.3,2,5.5c0.4,3,0.2,5.9-0.8,8.7c-1,2.8-2.6,5.5-4.5,8.1
                c-3.7,5-8.3,10.3-17.9,19.4c-12.7,12-24.8,22.3-31.8,27c-3.6,2.4-7.3,4-11.7,4.6c-4.5,0.7-8.5,0.5-12-0.3c-3.5-0.9-6.5-2.4-9-4.6
                s-4.5-5-6-8.4c-1.5-3.4-2.6-7.2-3.3-11.5c-0.7-4.6-0.8-10.6-0.3-18c0.5-7.4,2.1-16.1,4.8-26.1c0.4-2,1.1-4,1.9-6.5
                c4.5-13.1,8.9-24,13.4-32.7c4.5-8.7,8.8-15.7,12.9-20.9c4.1-5.2,8.1-9,11.8-11.4c3.7-2.3,7-3.7,9.8-4.1c3.3-0.5,6,0,8.2,1.6
                c2.1,1.5,3.4,3.9,3.9,7.1c0.5,3.7-0.3,7.8-2.4,12.3c-2.1,4.5-5.2,9.1-9.3,13.7c-6.1,6.8-11.9,13.4-18.5,18.3
                c-35.5,26.2-63.7,39.8-85.1,49.3c-3.4,1.6-7.3,3.5-11.7,5.7c-4.3,2.2-8.7,4.7-13.2,7.3s-8.8,5.5-13,8.5c-4.2,3-7.9,6.1-11,9.3
                c-3.1,3.2-5.6,6.4-7.3,9.7c-1.7,3.3-2.3,6.5-1.8,9.7c0.3,2.1,1.4,3.7,3.2,4.5c1.8,0.9,4.1,1.1,6.7,0.7c4.6-0.7,9.3-2.6,14.2-5.9
                s9.5-7.4,14.2-12.6c4.6-5.1,9-11.1,13.3-17.8c4.2-6.7,8-13.9,11.4-21.5c1.7-3.8,2-4.9,2.8-6.8c2.1-5.2,3.9-10.5,5.5-15.9
                c1.6-5.3,2.9-10.5,3.9-15.5c1-5,1.7-9.7,2.1-14.2c0.3-4.5,0.3-8.5-0.3-12c-0.4-2.7-1.2-5.1-2.4-7.2c-1.2-2.1-2.7-3.9-4.6-5.2
                c-1.8-1.4-4-2.3-6.4-2.9c-2.4-0.6-5.1-0.6-8-0.2c-5.3,0.8-10.3,2.2-14.9,4.1c-4.6,2-8.9,4.3-12.7,7.1c-3.9,2.8-7.1,5.8-10.8,9.3
                C30,64.7,25.7,69,18.6,76.9z"/>
        </svg>
    </Fragment>
)



export default helloSvg