import React , {Fragment} from 'react';
import classes from './IntroSvg.module.css';

const introSvg = () => {
    return (
        <Fragment>
            <svg version="1.1"
                 id="Layer_1"
                 xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px"
                 viewBox="0 0 900 380"
                 className={classes.SVG}>
                    <line className={classes.AnimatedPath} x1="828.6" y1="156.8" x2="838" y2="156.8"/>
                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="639.9826" y1="727.722" x2="518.8149" y2="282.2527">
                    <stop  offset="0" style={{stopColor: "#63BBDB"}} />
                    <stop  offset="1" style={{stopColor: "#2DA0DA"}} />
                    </linearGradient>
                    <path style={{fill: 'url(#SVGID_1_)'}} className={classes.Background} d="M1040,447.2L884.7,96.3c-2.9-5.1-3.7-5.9-9.7-3.1c-6,2.8-11.2,11.4-17.5,22.6s-13.2,30.9-13.2,30.9L820,94.2
                    c-2.6-4.8-4.5-9.7-14.4-13.6c-9.9-3.9-19.6-1.8-19.6-1.8l-26.3-59.4c-2-4.3-10.3-12.5-26.8-6.8s-20.3,17.3-23.5,27
                    s1.5,24.9,1.5,24.9S700.2,68.8,694,76c-3.3,3.8-14.8,10.3-13.4,29.2c0.5,5.9,13.5,29.1,13.6,32.9c0.1,5.4-5.2,0.3-5.2,0.3l-31-71.4
                    c-0.8-1.2-2.1-1.9-3.7-0.6c-1.7,1.3-0.4,3.1-1.8,4.1c-1.3,1-5.6,9.6-5.6,9.6l-12.6-37.9c-3.5-6.5-7-7.5-15.1-6.1
                    c-8.1,1.3-17.9,15.9-17.9,15.9S600.9,39,594,37.6c-6.9-1.4-10.1,1.7-14.4,8.5S573.4,60,573.4,60l-6.1-14.1c-0.5-0.6-2.3-1.9-3.7-2.3
                    s-2.8-0.2-3.7,0.3c-0.9,0.6-2.6,4.2-2.6,4.2c-6.8-4.2-13.4-5.9-24.1-1.7c-10.7,4.2-19.5,13.8-19.5,13.8l-10.6-23.9
                    c0,0-1.1-2.9-5.1-3.4s-6.6,2.2-9,6.8c-2.5,4.6-0.4,10.8-0.4,10.8l3.8,8.6c-2.3-1.5-5.7-2.1-7.1-1.9c-1.3,0.2-7.7,12.3-11.9,20.7
                    s-7.1,20.9-7.1,20.9l-18.5-41.8c-0.5-0.8-4.3-2.9-5.5-3.3c-1.2-0.4-2.3,0.4-3.1,2s-17,42.2-17,42.2l-9.7-21.9c0,0-3.8-7.3-13.2-7.1
                    c-9.4,0.2-16.4,7.1-23.6,14.5c-7.2,7.4-12.1,20.1-12.1,20.1l-13.5-30.5c-2.1-4.5-5.4-11.2-11.3-18.5s-15.1-13.2-15.1-13.2l-6.9-15.5
                    c-0.6-1.2-2.3-2.8-3.7-3.7c-1.4-0.9-2.9-1-4.5,0.7c-1.6,1.7-7.1,9.7-7.1,9.7c-4.9-1.2-13.9-2.2-23.6-1.6s-20.5,4-20.5,4
                    C248.9,20.6,239.9,8.6,229.2,5s-17.3,1.9-20.4,4.1s-3,4-3,4l17.4,39.4c-8.9,6.2-20.7,20-26.5,35c-5.8,15-1.2,25.8-1.2,25.8
                    l21.7,49.2c-5.6,4.3-3.3,11.7-3.3,11.7l10.2,23.1c-5.4,3.6-3.7,5.4-3.7,5.4l26.5,59.8c-7.1,3.7-10.3,5.6-18,13.7
                    c-7.7,8.1-14.6,27.5-14.6,27.5l-15.5-35.2c0,0-3.8-11-11.1-19.4c-7.4-8.5-18-15.2-18-15.2l-5.5-15.8c-0.7-1.2,0.2-0.6-1.4-1.5
                    c-1.6-0.9-2-0.4-3.1,0.2c-1.1,0.7-7.4,10.2-7.4,10.2s-25.4-6.2-49.9,3.5c-35.3,14-47.3,36.3-52.8,51.5s0.1,25.7,0.1,25.7L70.6,355
                    c0,0-2,1.8-3.2,4.6c-1.1,2.8-0.8,5.6,0.2,7.8l132.3,300H1040V459.8V447.2z"/>
                    <g>
                        <path className={classes.AnimatedUnderlines} d="M208.7,128.1c2.7,1.7,5.6,2.8,8.8,3.4c3.2,0.6,6.5,0.6,9.9-0.1c3.4-0.6,6.6-1.6,9.7-3c0,0,12.4-5.7,19.9-16.4
                            c7.5-10.7,11.7-24,8.5-45.7c-1-7-3.1-14-5.9-20.6c3.9-1.3,8-2.3,12.2-3.1c5.6-1,10.9-1.5,16-1.5 M209.6,16.5
                            c7.4,1.2,13.9,3.6,19.5,7 M352.7,125.3c0.2-0.8,0.5-1.6,0.7-2.4c1.3-5.1,2-10.4,2.1-15.8c0.1-5.4-0.4-10.7-1.4-16
                            c-1.1-5.9-2.9-11.6-5.4-16.8 M375.6,125.9c-0.1-0.7-0.1-1.4-0.1-2c0.9-0.2,1.8-0.4,2.6-0.6c2.2-0.6,4.4-1.4,6.7-2.3
                            c3.2-1.4,6.4-3,9.4-4.8c3.1-1.8,6-3.9,8.7-6.3c2.7-2.3,5-4.8,6.9-7.4c1.9-2.6,3.3-5.4,4.2-8.2c0.9-2.9,1.1-5.8,0.5-8.7
                            c-0.4-2.2-1.1-4-2.2-5.4 M446.4,68.7c0.1-1.1,0.3-2,0.6-2.9c0.3-0.8,0.6-1.6,0.9-2.3c0.3-0.7,0.6-1.3,0.8-1.8 M457.7,101.1
                            c-0.6-3.2-1.5-6.3-2.6-9.3 M480.8,107.4c0.3-2.7,0.8-5.7,1.5-8.8c0.7-3.2,1.6-6.5,2.6-9.9c1-3.4,2-6.7,3-9.8c1-3.1,1.9-6,2.8-8.6
                            c0.9-2.6,1.5-4.7,2-6.3 M565.7,61.5c0.1-0.2,0.1-0.4,0.2-0.6c0.9-3.1,1.7-5.6,2.3-7.5c0.6-1.9,0.9-2.8,0.9-2.9 M551.1,116.8
                            l2.4-10.4l1.5-6.5c1.3-5,2.6-9.8,3.9-14.5c1.3-4.7,2.6-9.2,3.8-13.3 M588.1,199.4c5-0.7,14-5.2,16.1-23.7
                            c0.8-7.2-0.8-14.1-3.9-20.3c0.7-0.7,1.4-1.4,2.1-2.1c12.7-11.7,29.7-21.2,54.2-17.1c24.5,4.1,45.8,24.5,49.5,27.5
                            c13.8,11.3,22.9,1.8,22.9,1.8 M526.6,177.2c9.4-12.5,14.5-25.5,19.6-42.6c0.5-1.5,0.9-3.1,1.4-4.6c8.9,0.2,18.2,2,26.5,5.6
                            M514.1,117.6c2,1.1,4.1,1.8,6.4,2c2.2,0.2,4.4,0.1,6.6-0.3 M476.3,121.1c2.2,1.7,4.9,2.2,8.3,1.6c3.6-0.7,6.7-2.2,9.4-4.6
                            M430.2,134.5c3.3,1.5,7,1.8,11.2,1c4.7-0.9,9-2.7,12.8-5.4c3.8-2.7,7.3-6,10.4-9.7 M423.1,121.1c2.9-4.8,6-11.3,8.5-17.9l0.1,0
                            c0-0.1,0.2-0.6,0.6-1.7c0.4-1.1,0.9-2.5,1.6-4.2s1.4-3.6,2.2-5.6c0.8-2.1,1.6-4.1,2.4-6.1 M370.8,138.2c2,2.4,4.2,4.1,6.8,5.3
                            c2.6,1.1,5.2,1.8,8.1,2s5.4,0,7.8-0.4c3.3-0.6,6.7-1.8,10.1-3.7c3.4-1.9,6.8-4.4,10.1-7.8 M219,180c0.8,1,1.9,1.8,3.2,2.4
                            c1.4,0.6,3,1,5,1.1c2,0.1,4.3-0.1,6.9-0.6c6.4-1.2,12.1-3.1,16.9-5.7c4.4-2.3,8.3-5.1,11.8-8.4l0.9-1l0.2,0
                            c5.9,0.5,12,0.8,18.3,0.9c6.3,0.1,12.9-0.4,19.7-1.7c7.6-1.4,14.3-3.5,20.1-6.4c5.8-2.8,10.8-6.2,15-10.1
                            c4.2-3.9,7.6-8.2,10.3-12.9c1.9-3.3,3.4-6.7,4.6-10.2c3-0.2,5.6-0.5,8-0.8 M245.5,162.1c-4.2-0.1-8.4,0.2-12.5,1
                            c-3,0.6-5.5,1.3-7.6,2.3 M584.1,182.2c1.1-5.2,4-10.9,7.7-16.3 M497.4,179c-2.6-12.8,1.1-29.7,18.4-41.5 M515,92.5
                            c1.2-4.9,3.3-10.1,6.3-15.4c2-3.6,4.3-6.7,6.7-9.3c2.4-2.6,4.8-4.7,7.3-6.5c2.5-1.7,4.9-3.1,7.2-4c2.3-1,4.5-1.6,6.4-2
                            c1.7-0.3,3.2-0.3,4.5-0.1 M379.6,105.3c1.9-4.7,4.2-8.9,6.7-12.6c2.5-3.7,5.1-6.7,7.9-9 M309.6,122.7c0,0,5,2.2,20.3,3.8
                            M277.5,147.9c2.7-5.9,5-12.1,7-18.7c2-6.6,3.8-13.5,5.4-20.6c1.6-7.1,3.3-14.3,5-21.6c1.7-7.3,3.6-14.5,5.8-21.7
                            c2-6.8,4.4-13.3,7.2-19.7l0.5-1c5.4,1.9,10,4.3,12.9,6.3 M207.2,108.2c-0.8-4.1-0.7-8.5,0.3-13c1-4.5,2.7-9,5.2-13.4
                            c2.5-4.4,5.7-8.7,9.6-12.9c3.9-4.1,8.4-8,13.4-11.5"/>
                        <path className={classes.AnimatedUnderlines} d="M165.1,229.5c1.6-3.2,1.5-2.8,3.4-5.8 M228,317.9c0.9-0.2,1.8-0.4,2.6-0.6c2.2-0.6,4.4-1.4,6.7-2.3
                            c3.2-1.4,6.4-3,9.4-4.8c3.1-1.8,6-3.9,8.7-6.3c2.7-2.3,5-4.8,6.9-7.4c1.9-2.6,3.3-5.4,4.2-8.2c0.9-2.9,1.1-5.8,0.5-8.7 M289,305.1
                            c0.1-3.5,0.6-7.5,1.4-11.9c0.8-4.4,1.9-9.1,3.4-14.2c1.5-5.1,5.7-15.9,5.7-15.9 M331.5,277.6c0.8-4.6,0.8-8.8,0.1-12.9
                            c-0.3-1.7-0.7-3.2-1.3-4.3 M349.6,299.3c-0.1-0.7-0.2-1.3-0.3-2c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.9,0-1.9,0-2.6
                            c3.6-0.4,6.9-1.2,9.4-1.9c3.3-0.8,6.5-1.5,9.4-3.2c3.1-1.8,6-3.9,8.7-6.3c2.7-2.3,5-4.8,6.9-7.4c1.9-2.6,3.3-5.4,4.2-8.2
                            c0.9-2.9,1.1-5.8,0.5-8.7 M223.9,206.8c0,0,9.3,3.7,16.2,7.9c18.1,11.2,37.6,23.4,62.6,23.4c24,0,36.7-8.7,48.1-21.4
                            c6.5,9.8,20.5,15.4,28.5,18.6 M359.2,210.1c11-13.5,13.6-24.6,13-33.1 M411.6,279.1c-0.4-1.9-0.6-4-0.5-6.2c0,0-0.1-5,0.1-7.8
                            c0-6.3,0.7-12.9,2-19.8c0.2-1.2,0.5-2.5,0.7-3.7c8.8-1,16.2-4.4,22.5-9.9c13.4-11.8,21.4-30.2,19.8-47.1 M457.6,269.5
                            c0.8-4.5,2.1-8.9,3.9-13.2c1.8-4.3,3.9-8.4,6.3-12.2 M500.4,251.4c0.2-2.1,0.2-4.2,0.1-6.3c-0.1-2.1-0.3-3.9-0.6-5.6 M461.2,296.2
                            c2.3,0.5,4.6,0.5,7.1,0c2.4-0.4,4.9-1.3,7.6-2.7c2.6-1.3,5.2-3.2,7.8-5.5c2.5-2.3,4.9-5.2,7.1-8.5c2.2-3.3,4.2-7.2,5.8-11.7l0-0.1
                            c0.9,0,2,0,3.1-0.2 M418.1,301.4c2.3,0.5,4.5,0.5,6.8,0.1c2.7-0.5,5.3-1.7,7.7-3.6c2.4-1.9,4.6-4.1,6.6-6.7 M359.4,316.8
                            c2.8,0.2,5.4,0,7.8-0.4c3.3-0.6,6.7-1.8,10.1-3.7c3.4-1.9,6.8-4.4,10.1-7.8 M295.7,325.7c1.5-0.3,3-0.8,4.5-1.6
                            c1.5-0.8,2.9-1.6,4.3-2.6c1.4-1,2.6-2,3.8-3.1c1.2-1.1,2.2-2,3.1-3c3.2-3.4,6.2-7.2,9-11.3c2.8-4.2,5.2-8.5,7.1-13
                            c0.4-1,0.8-1.9,1.2-2.9 M230.1,337.4c2.6,1.1,5.2,1.8,8.1,2s5.4,0,7.8-0.4c3.3-0.6,6.7-1.8,10.1-3.7c3.4-1.9,6.8-4.4,10.1-7.8
                            M74.4,376.3c1.4,0.6,3,1,5,1.1c2,0.1,4.3-0.1,6.9-0.6c6.4-1.2,12.1-3.1,16.9-5.7c4.4-2.3,8.3-5.1,11.8-8.4l0.9-1l0.2,0
                            c5.9,0.5,12,0.8,18.3,0.9c6.3,0.1,12.9-0.4,19.7-1.7c7.6-1.4,14.3-3.5,20.1-6.4c5.8-2.8,10.8-6.2,15-10.1
                            c4.2-3.9,7.6-8.2,10.3-12.9c1.9-3.3,3.4-6.7,4.6-10.2c3.1-0.2,5.8-0.5,8.4-0.9 M59.7,289.2c1-4.5,2.7-9,5.2-13.4
                            c2.5-4.4,5.7-8.7,9.6-12.9c3.9-4.1,8.4-8,13.4-11.5c5-3.5,10.6-6.5,16.6-9.1c6.1-2.6,12.5-4.5,19.4-5.8c5.6-1,10.9-1.5,16-1.5
                            M60.9,322.1c2.7,1.7,5.6,2.8,8.8,3.4c3.2,0.6,6.5,0.6,9.9-0.1c3.4-0.6,6.6-1.6,9.7-3c3.1-1.4,6.1-3,8.8-4.8c2.8-1.8,5.4-3.8,8-5.9
                            c2.5-2.1,4.9-4.3,7.3-6.5 M484,250.1c-0.6-1.9-1.1-3.6-1.4-5.2c-0.3-1.8-0.5-3.5-0.4-5.3c0.1-1.7,0.3-3.3,0.7-4.7
                            c0.4-1.4,1.1-2.5,1.9-3.5 M351.4,193.3c0-5.9,1.9-10.7,3.4-13.7c1.4-2.8,3.4-5,5.4-6.2 M417.9,224c4.9-17.8,6.1-21.6,12.2-32.9
                            M76.5,365c1.9-1.5,5.1-2.7,9.5-3.5 M162.1,316.7c0,0,5,2.2,20.3,3.8 M129.6,341.9c2.7-5.9,5-12.1,7-18.7c2-6.6,3.8-13.5,5.4-20.6
                            c1.6-7.1,3.3-14.3,5-21.6c1.7-7.3,3.6-14.5,5.8-21.7c2-6.8,4.4-13.4,7.2-19.7 M232.1,299.3c1.9-4.7,4.2-8.9,6.7-12.6
                            c2.5-3.7,5.1-6.7,7.9-9 M353.2,276.7c1.9-4.7,4.2-8.9,6.7-12.6c2.5-3.7,5.1-6.7,7.9-9"/>
                        <path className={classes.AnimatedUnderlines} d="M495.3,53.5c0.6,0.6,1.4,0.8,2.4,0.6c1.1-0.2,2.1-0.7,3-1.5c0.9-0.8,1.8-1.8,2.5-2.9c0.7-1.1,1.2-2.3,1.5-3.6
                            c0.3-1.3,0.4-2.5,0.2-3.6"/>
                        <path className={classes.AnimatedUnderlines} d="M633.2,84.8c0.9-2.5,1.8-5.1,2.8-7.7c1-2.7,1.9-5.4,2.7-8.1c0.8-2.7,1.4-5.4,1.8-8.1c0.4-2.6,0.3-5.2-0.1-7.6"
                            />
                        <path className={classes.AnimatedUnderlines} d="M625.9,103.8c1,0.7,2.2,1.2,3.5,1.5c1.3,0.3,2.7,0.3,4.3,0c2.6-0.5,5.1-1.7,7.4-3.7c2.3-2,4.5-4.4,6.5-7.2
                            c2-2.8,3.9-5.8,5.5-8.9c1.6-3.2,3.1-6.2,4.4-9.1"/>
                        <path className={classes.AnimatedUnderlines} d="M585.4,111.8c0.5-1,1.1-2.1,1.7-3.4c0.6-1.3,1.3-2.7,2-4.1c0.7-1.4,1.4-2.9,2.1-4.4c0.7-1.5,1.4-2.9,2.2-4.3
                            c1.7-3.3,3.3-6.6,5.1-10.2c3-6.1,5.9-11.5,8.6-16.3c2.7-4.8,5.2-8.9,7.6-12.3c2.4-3.4,4.6-6.1,6.7-8"/>
                        <path className={classes.AnimatedUnderlines} d="M576,80.4c0.6-1.3,1.3-3,2.1-5.2c0.8-2.1,1.7-4.4,2.7-6.8c0.9-2.4,2-4.8,3-7.2c1.1-2.4,2.2-4.6,3.3-6.6
                            c1.1-2,2.3-3.6,3.5-5"/>
                        <path className={classes.AnimatedUnderlines} d="M728.7,67.5c2.3-1.4,4.6-2.7,6.9-4.1c3.4-2,6.6-4,9.8-6c3.1-2,6-4.1,8.6-6.3c2.6-2.2,4.8-4.4,6.6-6.8
                            c1.8-2.4,3.1-4.9,3.9-7.5c0.8-2.6,0.9-5.4,0.4-8.4 M766.1,102.1c2.4-3.4,4.4-6.8,6-10.3c1.5-3.5,2.6-7,3.1-10.5
                            c0.5-3.5,0.5-6.8-0.1-10 M785.4,90.4c0.9-0.4,2-0.7,3.1-1c1.1-0.3,2.3-0.6,3.5-0.8c2.8-0.5,5.5-0.6,8.2-0.3 M696.6,126.2
                            c3.2,1.7,7.1,2.8,11.7,3.3c4.6,0.5,10,0.1,16.2-1c5-0.9,9.7-2.4,14.2-4.4c3.1-1.4,6-3,8.9-4.7c0,0,1.4-0.8,3.6-2.4
                            c3.6,2.5,7.3,4.7,11.3,6.5c4,1.9,8,3.3,12.2,4.4c4.2,1.1,8.5,1.7,12.9,1.9c4.4,0.2,8.9-0.1,13.6-1c4-0.7,7.6-1.9,10.8-3.6
                            c3.2-1.6,5.9-3.6,8-6c2.1-2.3,3.7-4.9,4.6-7.8c0.9-2.9,1.1-5.9,0.5-9 M722.1,44c0.9-4.6,2.5-8.4,4.6-11.6c2.2-3.2,4.8-5.7,7.9-7.6
                            c3.1-1.9,6.2-3.1,9.5-3.7c2.4-0.4,4.5-0.5,6.4-0.2 M696.2,97.7c0.9-3,2.3-5.9,4.2-8.5c1.9-2.7,4.4-5.2,7.2-7.6"/>
                        <path className={classes.AnimatedUnderlines} d="M531.6,215.2l3.2-11.9 M554,263c0.5-2.4,1.2-4.9,2-7.5c0.9-2.6,1.9-5.2,3-7.9c1.1-2.7,2.1-5.5,2.9-8.2
                            c0.8-2.8,1.5-5.5,1.9-8.3 M671.5,235.4c0.7-2.3,1.5-4.7,2.3-7.3c0.8-2.5,1.6-5.1,2.2-7.8c0.7-2.7,1.1-5.4,1.4-8.1
                            c0.2-2.7,0.1-5.5-0.4-8.1 M710.3,224.7c3.6-0.8,7.1-1.5,10.2-3.4c3.1-1.8,6-3.9,8.7-6.3c2.7-2.3,5-4.8,6.9-7.4
                            c1.9-2.6,3.3-5.4,4.2-8.2c0.9-2.9,1.1-5.8,0.5-8.7 M701.8,231.2c-0.1-0.7-0.1-1.4-0.1-2.1c0-1.6,0-3.7,0-3.7
                            c0.8-5.1,2.1-10,4.1-14.8c1.9-4.7,4.2-8.9,6.7-12.6c2.5-3.7,5.1-6.7,7.9-9 M814.7,213.4c0.9-2.5,1.8-5.1,2.8-7.7
                            c1-2.7,1.9-5.4,2.7-8.1c0.8-2.7,1.4-5.4,1.8-8.1c0.4-2.6,0.3-5.2-0.1-7.6 M877.8,139.4c10.2-8.7,16.4-22,11.8-36.7 M865.2,132.9
                            c1.7-4.9,4.7-11.2,6.4-15.7c1.4-3,3.5-8.1,5.9-12 M849.8,198.1c0.2-4.1,0.7-8.6,1.6-13.5s1.9-10,3.2-15.4c0.9-3.9,2-7.9,3.1-11.9
                            l0.2-0.7c7.6-1.2,26.9-4.1,38.1-6.1 M852.7,222.2c2,0.5,4.3,0.6,6.8,0.1c4.1-0.8,7.7-2.5,10.9-5.2s5.9-5.9,8.3-9.6
                            c2.4-3.7,4.5-7.5,6.3-11.6c1.8-4.1,3.4-8,4.7-11.6 M811,233.9c1.3,0.3,2.7,0.3,4.3,0c2.6-0.5,5.1-1.7,7.4-3.7
                            c2.3-2,4.5-4.4,6.5-7.2c2-2.8,3.9-5.8,5.5-8.9 M767,240.4c0.5-1,1.1-2.1,1.7-3.4s1.3-2.7,2-4.1c0.7-1.4,1.4-2.9,2.1-4.4
                            c0.7-1.5,1.4-2.9,2.2-4.3c1.7-3.3,3.3-6.6,5.1-10.2c3-6.1,5.9-11.5,8.6-16.3c2.7-4.8,5.2-8.9,7.6-12.3c2.4-3.4,4.6-6.1,6.7-8
                            M711.8,250.7c2.8,0.2,5.4,0,7.8-0.4c3.3-0.6,6.7-1.8,10.1-3.7c3.4-1.9,6.8-4.4,10.1-7.8c3.3-3.3,6.4-7.5,9.5-12.4
                            c3-4.9,5.7-10.7,8.3-17.4l0,0c0.6-1.3,1.3-3,2.1-5.2c0.8-2.1,1.7-4.4,2.7-6.8c0.9-2.4,2-4.8,3-7.2c1.1-2.4,2.2-4.6,3.3-6.6
                            M668.3,256c1.2,0.1,2.3,0.1,3.5-0.1c2.1-0.4,4.1-1.4,6.1-2.9c1.9-1.5,3.7-3.4,5.3-5.6c1.6-2.2,3.2-4.6,4.5-7.2 M634.7,260.9
                            c1.5-4.2,3-8.5,4.7-13c1.6-4.5,3.3-9,5-13.4c1.7-4.4,3.5-8.6,5.3-12.6c1.8-4,3.6-7.6,5.5-10.7c1.9-3.1,3.7-5.7,5.6-7.7
                            M598.2,271.4c3.1-5.2,5.9-10.5,8.4-16c2.5-5.4,4.7-10.7,6.8-15.7c2-5.1,3.9-9.9,5.7-14.4c1.7-4.5,3.4-8.5,5-12
                            c1.6-3.5,3.2-6.3,4.9-8.4 M558,278.4c2.1-0.4,4.2-1.4,6.2-3c2-1.6,3.9-3.6,5.6-5.9c1.8-2.3,3.4-4.8,4.9-7.5c1.5-2.7,2.8-5.3,4-8
                            c1.2-2.6,2.2-5.1,3-7.3c0.8-2.2,1.4-4,1.8-5.4l-0.1-0.1c2-4.4,3.8-8.4,5.3-12.1c1.5-3.7,3-6.9,4.5-9.6c1.4-2.7,2.9-4.9,4.4-6.5
                            M506.4,301.4c1.4-4.9,2.8-10,4.3-15.3c0-0.1,0.3-1,0.9-2.8c0.6-1.8,1.4-4.2,2.4-7.2c1.1-3,2.4-6.4,3.9-10.2
                            c1.5-3.8,3.2-7.7,5.1-11.8c1.9-4,3.9-8,6.2-12c2.2-3.9,4.6-7.5,7-10.7c2.5-3.2,5.1-5.9,7.8-8.1 M355.7,348c0,0,7.2-1.4,17.3-0.9
                            c11.8,0.6,21.2,2.5,62,17.7c40.8,15.2,71.6,13.4,118.2-11.5c3.4-1.8,6.8-3.7,10.2-5.6c18.9,5.4,40.6,8.8,64.6,7.1
                            c54.8-4,98.6-21.1,109.4-42.9 M470.2,324.9c1.2,4.2,11.4,6.4,19.6,3.9c5.6-1.7,8.3-4.1,10.2-7.6c0.5,0.2,0.9,0.4,1.4,0.6
                            c10.9,5.2,25.8,13.2,44.2,20.1 M577.2,339.9c37.4-21.4,72.5-42.9,109.7-45.9c15.6-1.2,28.2,0.6,36.3,4.4"/>
                        <line className={classes.AnimatedUnderlines} x1="828.6" y1="156.1" x2="838" y2="156.1"/>
                    </g>
                    <path className={classes.AnimatedPath1} d="M855.3,146.9c5.5-2.5,14.2-9.5,17.9-12.7c10.2-8.7,16.4-22,11.8-36.7c-4.6-14.7-19.8,3.9-24.6,12.6
                    s-6.1,12.4-9.5,20.5c-2.3,5.4-4.5,12.1-5.8,15.9l-0.2,0.6c-7.1,2.4-14.8,4.4-22.6,6.1c0,0,8.7,0.5,20.7-0.6l-0.4,1.5
                    c-1.5,4.9-2.7,9.6-3.6,13.9c-1.2,5.7-2,10.7-2.5,15.2c-0.5,4.5-0.7,8.3-0.7,11.5c0,3.2,0.2,5.6,0.5,7.3c0.5,2.6,1.2,4.9,2.3,7
                    c1.1,2.1,2.4,3.8,4,5.1c1.6,1.4,3.4,2.3,5.4,2.9c2,0.5,4.3,0.6,6.8,0.1c4.1-0.8,7.7-2.5,10.9-5.2c3.1-2.7,5.9-5.9,8.3-9.6
                    c2.4-3.7,4.5-7.5,6.3-11.6c1.8-4.1,3.4-8,4.7-11.6l-3.5-1.8c-0.2,0.4-0.6,1.5-1.3,3.3c-0.7,1.8-1.6,3.9-2.8,6.3
                    c-1.1,2.4-2.5,5-4,7.8c-1.5,2.8-3.2,5.4-5.1,7.8c-1.8,2.4-3.8,4.5-5.8,6.3c-2.1,1.7-4.2,2.8-6.4,3.2c-2.5,0.5-4.7,0-6.7-1.2
                    c-2-1.3-3.3-3.7-4-7.4c-0.5-2.9-0.7-6.4-0.5-10.6c0.2-4.1,0.7-8.6,1.6-13.5c0.8-4.8,1.9-10,3.2-15.4c0.9-3.9,2-7.9,3.1-11.9l0.2-0.7
                    c7.6-1.2,26.9-4.1,38.1-6.1l-0.6-1.8c0,0-28.9,4.9-37.4,5.1l1.2-3.7c1.6-5.2,4.4-12.3,6.1-17.2c1.7-4.9,4.7-11.2,6.4-15.7
                    c2.6-5.7,7.6-18.7,13.1-17.4c1.6,0.4,5.6,7.8,3.6,16.5s-8.7,16.8-13.7,20.8c-5,4-7,5.4-12.5,7.9"/>
                    <path className={classes.AnimatedPath2} d="M230.2,7.3c14.3,9.7,27.7,31.5,30.8,53.2c3.2,21.7-1,35-8.5,45.7c-7.5,10.7-19.9,16.4-19.9,16.4
                    c-3.1,1.4-6.3,2.4-9.7,3s-6.7,0.6-9.9,0.1c-3.2-0.6-6.2-1.7-8.8-3.4c-2.7-1.7-4.9-3.8-6.8-6.5c-1.9-2.7-3.2-5.8-3.8-9.3
                    c-0.8-4-0.6-8.4,0.4-13.2c1-4.8,2.8-9.6,5.4-14.6c2.6-5,6-9.9,10.2-14.7c4.2-4.8,9.1-9.3,14.7-13.5c5.6-4.1,12-7.8,19.1-10.9
                    c7.1-3.1,14.8-5.5,23.2-7.1c6.1-1.1,12-1.6,17.6-1.5c5.6,0.1,12.9,1.2,21.7,3.7c5.5,1.6,11.1,4.5,16,7.8c4.9,3.3,9.2,7.1,12.9,11.5
                    c3.7,4.3,6.9,9.2,9.4,14.5c2.5,5.3,4.3,10.9,5.4,16.8c1,5.3,1.4,10.6,1.4,16c-0.1,5.4-0.8,10.7-2.1,15.8c-1.3,5.1-3.3,10.1-6,14.8
                    c-2.7,4.7-6.1,9-10.3,12.9c-4.2,3.9-9.2,7.2-15,10.1c-5.8,2.8-12.5,5-20.1,6.4c-6.8,1.3-13.3,1.8-19.7,1.7
                    c-6.3-0.1-12.5-0.4-18.3-0.9l-9.3-0.8c-3.9-0.3-7.6-0.4-11-0.4c-3.5,0-6.8,0.3-9.8,0.8c-4.4,0.8-7.5,2-9.5,3.5
                    c-1.9,1.5-2.8,3.1-2.5,4.8c0.2,1.3,1.2,2.3,2.9,2.9c1.7,0.7,4.5,0.6,8.2,0c4.5-0.8,8.6-2.2,12.1-4.2c2.9-1.6,5.5-3.5,7.9-5.8
                    l6.7-7.7c2.8-4,5.3-8.5,7.4-13.6c2.4-5.8,4.6-12.1,6.6-18.8c2-6.7,3.8-13.7,5.6-21c1.7-7.3,3.7-14.6,5.8-22c2.1-7.4,4.6-14.7,7.3-22
                    c2.6-6.8,5.7-13.4,9.3-19.7l4.3-7c2.2-3.3,4.6-6.4,7.2-9.5l3.1,2.3c-1.9,3-3.7,6.1-5.3,9.3l-3.1,6.6c-2.8,6.3-5.2,12.9-7.2,19.7
                    c-2.1,7.2-4.1,14.4-5.8,21.7c-1.7,7.3-3.4,14.5-5,21.6c-1.6,7.1-3.4,14-5.4,20.6s-4.3,12.8-7,18.7c-2.2,4.8-4.8,9.3-7.8,13.3
                    l-6.7,7.7c-3.5,3.3-7.4,6.1-11.8,8.4c-4.8,2.6-10.5,4.5-16.9,5.7c-2.6,0.5-4.9,0.7-6.9,0.6c-2-0.1-3.7-0.5-5-1.1
                    c-1.4-0.6-2.4-1.4-3.2-2.4c-0.8-1-1.3-2.1-1.5-3.2c-0.3-1.4-0.2-2.8,0.3-4.2c0.5-1.4,1.3-2.7,2.6-3.9c1.3-1.2,2.9-2.3,5-3.3
                    c2.1-1,4.6-1.7,7.6-2.3c4.1-0.8,8.2-1.1,12.5-1c4.2,0.1,8.5,0.3,12.9,0.7l9.3,0.9c4.7,0.4,9.4,0.6,14,0.7c4.7,0.1,9.4-0.3,14.1-1.2
                    c9.9-1.8,18.2-5.1,25.1-9.9c6.9-4.8,12.3-10.6,16.2-17.4c4-6.8,6.5-14.4,7.5-22.7c1.1-8.4,0.8-17.1-0.9-26.1
                    c-1-5.2-2.5-10.1-4.5-14.5c-2.1-4.5-4.6-8.5-7.6-12.1c-3-3.6-6.5-6.8-10.4-9.4c-3.9-2.7-11-6.2-19-8.1c-4.7-1.1-9.3-1.5-14.4-1.6
                    c-5.1-0.1-10.4,0.4-16,1.5c-6.8,1.3-13.3,3.2-19.4,5.8c-6.1,2.6-11.6,5.6-16.6,9.1c-5,3.5-9.5,7.3-13.4,11.5
                    c-3.9,4.2-7.1,8.4-9.6,12.9c-2.5,4.4-4.3,8.9-5.2,13.4c-1,4.5-1.1,8.8-0.3,13c0.5,2.7,1.5,5.1,3,7.3c1.5,2.2,3.4,4.1,5.5,5.5
                    c2.2,1.5,4.6,2.5,7.4,3.1c2.7,0.6,5.6,0.6,8.5,0.1c2.3-0.4,4.6-1,6.7-2.1c5.7-2.8,20.5-11.5,22.3-35.3c1.8-23.8-13.2-64.4-51.1-70.4
                    C205,10.6,215.8-2.3,230.2,7.3z"/>
                    <path className={classes.AnimatedPath3} d="M107.3,349c2.8-4,5.3-8.5,7.4-13.6c2.4-5.8,4.6-12.1,6.6-18.8c2-6.7,3.8-13.7,5.6-21c1.7-7.3,3.7-14.6,5.8-22
                    c2.1-7.4,4.6-14.7,7.3-22c2.6-6.8,5.7-13.4,9.3-19.7l4.3-7c2.2-3.3,4.6-6.4,7.2-9.5l3.1,2.3c-1.9,3-3.7,6.1-5.3,9.3l-3.1,6.6
                    c-2.8,6.3-5.2,12.9-7.2,19.7c-2.1,7.2-4.1,14.4-5.8,21.7c-1.7,7.3-3.4,14.5-5,21.6c-1.6,7.1-3.4,14-5.4,20.6c-2,6.6-4.3,12.8-7,18.7
                    c-2.2,4.8-4.8,9.3-7.8,13.3l-6.7,7.7c-3.5,3.3-7.4,6.1-11.8,8.4c-4.8,2.6-10.5,4.5-16.9,5.7c-2.6,0.5-4.9,0.7-6.9,0.6
                    c-2-0.1-3.7-0.5-5-1.1c-1.4-0.6-2.4-1.4-3.2-2.4c-0.8-1-1.3-2.1-1.5-3.2c-0.3-1.4-0.2-2.8,0.3-4.2c0.5-1.4,1.3-2.7,2.6-3.9
                    c1.3-1.2,2.9-2.3,5-3.3c2.1-1,4.6-1.7,7.6-2.3c4.1-0.8,8.2-1.1,12.5-1c4.2,0.1,8.5,0.3,12.9,0.7l9.3,0.9c4.7,0.4,9.4,0.6,14,0.7
                    c4.7,0.1,9.4-0.3,14.1-1.2c9.9-1.8,18.2-5.1,25.1-9.9c6.9-4.8,12.3-10.6,16.2-17.4c4-6.8,6.5-14.4,7.5-22.7
                    c1.1-8.4,0.8-17.1-0.9-26.1c-1-5.2-2.5-10.1-4.5-14.5c-2.1-4.5-4.6-8.5-7.6-12.1c-3-3.6-6.5-6.8-10.4-9.4c-3.9-2.7-11-6.2-19-8.1
                    c-4.7-1.1-9.3-1.5-14.4-1.6c-5.1-0.1-10.4,0.4-16,1.5c-6.8,1.3-13.3,3.2-19.4,5.8c-6.1,2.6-11.6,5.6-16.6,9.1
                    c-5,3.5-9.5,7.3-13.4,11.5c-3.9,4.2-7.1,8.4-9.6,12.9c-2.5,4.4-4.3,8.9-5.2,13.4c-1,4.5-1.1,8.8-0.3,13c0.5,2.7,1.5,5.1,3,7.3
                    c1.5,2.2,3.4,4.1,5.5,5.5c2.2,1.5,4.6,2.5,7.4,3.1c2.7,0.6,5.6,0.6,8.5,0.1c2.3-0.4,4.5-1.1,6.7-2.1c2.2-1,4.2-2,6.1-3.2
                    c1.9-1.2,3.7-2.4,5.3-3.6c1.6-1.3,3-2.4,4.2-3.5c1.2-1.1,2.1-1.9,2.8-2.6c0.7-0.7,1-1,1.1-1.1l3.3,3c-2.3,2.2-4.7,4.4-7.3,6.5
                    c-2.5,2.1-5.2,4.1-8,5.9c-2.8,1.8-5.7,3.4-8.8,4.8c-3.1,1.4-6.3,2.4-9.7,3c-3.4,0.6-6.7,0.6-9.9,0.1c-3.2-0.6-6.2-1.7-8.8-3.4
                    c-2.7-1.7-4.9-3.8-6.8-6.5c-1.9-2.7-3.2-5.8-3.8-9.3c-0.8-4-0.6-8.4,0.4-13.2c1-4.8,2.8-9.6,5.4-14.6c2.6-5,6-9.9,10.2-14.7
                    c4.2-4.8,9.1-9.3,14.7-13.5c5.6-4.1,12-7.8,19.1-10.9c7.1-3.1,14.8-5.5,23.2-7.1c6.1-1.1,12-1.6,17.6-1.5c5.6,0.1,12.9,1.2,21.7,3.7
                    c5.5,1.6,11.1,4.5,16,7.8c4.9,3.3,9.2,7.1,12.9,11.5c3.7,4.3,6.9,9.2,9.4,14.5c2.5,5.3,4.3,10.9,5.4,16.8c1,5.3,1.4,10.6,1.4,16
                    c-0.1,5.4-0.8,10.7-2.1,15.8c-1.3,5.1-3.3,10.1-6,14.8c-2.7,4.7-6.1,9-10.3,12.9c-4.2,3.9-9.2,7.2-15,10.1c-5.8,2.8-12.5,5-20.1,6.4
                    c-6.8,1.3-13.3,1.8-19.7,1.7c-6.3-0.1-12.5-0.4-18.3-0.9l-9.3-0.8c-3.9-0.3-7.6-0.4-11-0.4c-3.5,0-6.8,0.3-9.8,0.8
                    c-4.4,0.8-7.5,2-9.5,3.5c-1.9,1.5-2.8,3.1-2.5,4.8c0.2,1.3,1.2,2.3,2.9,2.9c1.7,0.7,4.5,0.6,8.2,0c4.5-0.8,8.6-2.2,12.1-4.2
                    c2.9-1.6,5.5-3.5,7.9-5.8L107.3,349z"/>
                    <path className={classes.AnimatedPath4} d="M427.2,96.7c-2.5,6.8-5.7,13.6-8.7,18.5c-3,4.9-6.2,9.1-9.5,12.4c-3.3,3.3-6.6,5.9-10.1,7.8
                    c-3.4,1.9-6.8,3.1-10.1,3.7c-2.4,0.4-5,0.6-7.8,0.4c-2.8-0.2-5.5-0.8-8.1-2s-4.8-2.9-6.8-5.3c-2-2.4-3.3-5.5-4.1-9.4
                    c-0.8-4.2-0.8-8.4-0.1-12.5c0.7-4.2,1.9-8.1,3.6-11.9c1.7-3.8,3.7-7.3,6.2-10.7c2.5-3.3,5-6.3,7.7-8.8c2.7-2.5,5.4-4.6,8.1-6.2
                    c2.7-1.6,5.2-2.7,7.4-3.1c1.5-0.3,3-0.3,4.6-0.2c1.6,0.2,3.1,0.6,4.5,1.3c1.4,0.7,2.6,1.8,3.6,3.2c1,1.4,1.8,3.2,2.2,5.4
                    c0.6,3,0.4,5.9-0.5,8.7c-0.9,2.9-2.3,5.6-4.2,8.2c-1.9,2.6-4.2,5.1-6.9,7.4c-2.7,2.3-5.6,4.4-8.7,6.3c-3.1,1.8-6.2,3.4-9.4,4.8
                    c-2.3,1-4.5,1.8-6.7,2.3c-8.2,2.1-18.1,3.4-18.1,3.4c-6.9,1-14.8,1.7-30.1,0c-15.3-1.7-20.3-3.8-20.3-3.8l2-2.3
                    c0,0,3.5,1.3,15.3,3.5c11.8,2.2,22.1,2.3,32.8,0.4c6.3-1.1,11.9-2.6,18.3-5c2.7-1,5.2-2.1,7.6-3.5c3.1-1.8,5.8-3.8,8.3-6
                    c2.4-2.2,4.5-4.5,6.3-7c1.8-2.4,3.2-4.8,4.3-7.1c1.1-2.3,1.8-4.5,2.2-6.6c0.4-2,0.5-3.8,0.2-5.3s-0.9-2.6-1.9-3.4
                    c-1-0.7-2-1-3.1-0.8c-2.5,0.5-5.1,1.9-7.8,4.2c-2.8,2.3-5.4,5.3-7.9,9c-2.5,3.7-4.7,7.9-6.7,12.6c-1.9,4.7-3.3,9.6-4.1,14.8
                    c0,0,0,2.2,0,3.7c0,0.7,0.1,1.4,0.1,2.1c0.1,0.7,0.2,1.4,0.3,2c0.4,2,1,3.9,2,5.6c0.9,1.7,2.1,3.1,3.5,4.3c1.4,1.2,3,2,4.9,2.4
                    c1.9,0.5,3.9,0.5,6.1,0.1c7.3-1.4,14-5.2,19.9-11.4c5.9-6.3,11.2-15.4,16-27.5"/>
                    <path className={classes.AnimatedPath5} d="M275,303.2c-3,4.9-10.1,15.1-13.4,18.4c-3.3,3.3-6.6,5.9-10.1,7.8c-3.4,1.9-6.8,3.1-10.1,3.7
                    c-2.4,0.4-5,0.6-7.8,0.4c-2.8-0.2-5.5-0.8-8.1-2s-4.8-2.9-6.8-5.3c-2-2.4-3.3-5.5-4.1-9.4c-0.8-4.2-0.8-8.4-0.1-12.5
                    c0.7-4.2,1.9-8.1,3.6-11.9c1.7-3.8,3.7-7.3,6.2-10.7c2.5-3.3,5-6.3,7.7-8.8c2.7-2.5,5.4-4.6,8.1-6.2c2.7-1.6,5.2-2.7,7.4-3.1
                    c1.5-0.3,3-0.3,4.6-0.2c1.6,0.2,3.1,0.6,4.5,1.3c1.4,0.7,2.6,1.8,3.6,3.2c1,1.4,1.8,3.2,2.2,5.4c0.6,3,0.4,5.9-0.5,8.7
                    c-0.9,2.9-2.3,5.6-4.2,8.2c-1.9,2.6-4.2,5.1-6.9,7.4c-2.7,2.3-5.6,4.4-8.7,6.3c-3.1,1.8-6.2,3.4-9.4,4.8c-2.3,1-4.5,1.8-6.7,2.3
                    c-8.2,2.1-18.1,3.4-18.1,3.4c-6.9,1-14.8,1.7-30.1,0c-15.3-1.7-20.3-3.8-20.3-3.8l2-2.3c0,0,3.5,1.3,15.3,3.5
                    c11.8,2.2,22.1,2.3,32.8,0.4c6.3-1.1,11.9-2.6,18.3-5c2.7-1,5.2-2.1,7.6-3.5c3.1-1.8,5.8-3.8,8.3-6c2.4-2.2,4.5-4.5,6.3-7
                    c1.8-2.4,3.2-4.8,4.3-7.1c1.1-2.3,1.8-4.5,2.2-6.6c0.4-2,0.5-3.8,0.2-5.3c-0.3-1.5-0.9-2.6-1.9-3.4c-1-0.7-2-1-3.1-0.8
                    c-2.5,0.5-5.1,1.9-7.8,4.2c-2.8,2.3-5.4,5.3-7.9,9c-2.5,3.7-4.7,7.9-6.7,12.6c-1.9,4.7-3.3,9.6-4.1,14.8c0,0,0,2.2,0,3.7
                    c0,0.7,0.1,1.4,0.1,2.1c0.1,0.7,0.2,1.4,0.3,2c0.4,2,1,3.9,2,5.6c0.9,1.7,2.1,3.1,3.5,4.3c1.4,1.2,3,2,4.9,2.4
                    c1.9,0.5,3.9,0.5,6.1,0.1c7.3-1.4,14-5.2,19.9-11.4c5.9-6.3,11.2-15.4,16-27.5"/>
                    <path className={classes.AnimatedPath6} d="M353.7,281.1c4.3-1.6,6.9-3.6,9.3-5.8c2.4-2.2,4.5-4.5,6.3-7c1.8-2.4,3.2-4.8,4.3-7.1c1.1-2.3,1.8-4.5,2.2-6.6
                    c0.4-2,0.5-3.8,0.2-5.3c-0.3-1.5-0.9-2.6-1.9-3.4c-1-0.7-2-1-3.1-0.8c-2.5,0.5-5.1,1.9-7.8,4.2c-2.8,2.3-5.4,5.3-7.9,9
                    c-2.5,3.7-4.7,7.9-6.7,12.6c-1.9,4.7-3.3,9.6-4.1,14.8c0,0,0,2.2,0,3.7c0,0.7,0.1,1.4,0.1,2.1c0.1,0.7,0.2,1.4,0.3,2
                    c0.4,2,1,3.9,2,5.6c0.9,1.7,2.1,3.1,3.5,4.3c1.4,1.2,3,2,4.9,2.4c1.9,0.5,3.9,0.5,6.1,0.1c7.3-1.4,14-5.2,19.9-11.4
                    c5.9-6.3,11.2-15.4,16-27.5l1.5,10c-3,4.9-12.7,18.8-16,22.1c-3.3,3.3-6.6,5.9-10.1,7.8c-3.4,1.9-6.8,3.1-10.1,3.7
                    c-2.4,0.4-5,0.6-7.8,0.4c-2.8-0.2-5.5-0.8-8.1-2s-4.8-2.9-6.8-5.3c-2-2.4-3.3-5.5-4.1-9.4c-0.8-4.2-0.8-8.4-0.1-12.5
                    c0.7-4.2,1.9-8.1,3.6-11.9c1.7-3.8,3.7-7.3,6.2-10.7c2.5-3.3,5-6.3,7.7-8.8c2.7-2.5,5.4-4.6,8.1-6.2c2.7-1.6,5.2-2.7,7.4-3.1
                    c1.5-0.3,3-0.3,4.6-0.2c1.6,0.2,3.1,0.6,4.5,1.3c1.4,0.7,2.6,1.8,3.6,3.2c1,1.4,1.8,3.2,2.2,5.4c0.6,3,0.4,5.9-0.5,8.7
                    c-0.9,2.9-2.3,5.6-4.2,8.2c-1.9,2.6-4.2,5.1-6.9,7.4c-2.7,2.3-5.6,4.4-8.7,6.3c-3.1,1.8-6.6,2.5-10.2,3.4"/>
                    <path className={classes.AnimatedPath7} d="M753.1,202.8c-2.5,6.8-5.3,12.7-8.4,17.7c-3,4.9-6.2,9.1-9.5,12.4c-3.3,3.3-6.6,5.9-10.1,7.8
                    c-3.4,1.9-6.8,3.1-10.1,3.7c-2.4,0.4-5,0.6-7.8,0.4s-5.5-0.8-8.1-2c-2.6-1.1-4.8-2.9-6.8-5.3c-2-2.4-3.3-5.5-4.1-9.4
                    c-0.8-4.2-0.8-8.4-0.1-12.5c0.7-4.2,1.9-8.1,3.6-11.9c1.7-3.8,3.7-7.3,6.2-10.7c2.5-3.3,5-6.3,7.7-8.8c2.7-2.5,5.4-4.6,8.1-6.2
                    c2.7-1.6,5.2-2.7,7.4-3.1c1.5-0.3,3-0.3,4.6-0.2c1.6,0.2,3.1,0.6,4.5,1.3c1.4,0.7,2.6,1.8,3.6,3.2c1,1.4,1.8,3.2,2.2,5.4
                    c0.6,3,0.4,5.9-0.5,8.7c-0.9,2.9-2.3,5.6-4.2,8.2c-1.9,2.6-4.2,5.1-6.9,7.4c-2.7,2.3-5.6,4.4-8.7,6.3c-3.1,1.8-6.6,2.5-10.2,3.4
                    c-2.4,0.6-2.1-2.9,0.5-3.8c4.3-1.6,6.9-3.6,9.3-5.8c2.4-2.2,4.5-4.5,6.3-7c1.8-2.4,3.2-4.8,4.3-7.1c1.1-2.3,1.8-4.5,2.2-6.6
                    c0.4-2,0.5-3.8,0.2-5.3c-0.3-1.5-0.9-2.6-1.9-3.4c-1-0.7-2-1-3.1-0.8c-2.5,0.5-5.1,1.9-7.8,4.2c-2.8,2.3-5.4,5.3-7.9,9
                    c-2.5,3.7-4.7,7.9-6.7,12.6c-1.9,4.7-3.3,9.6-4.1,14.8c0,0,0,2.2,0,3.7c0,0.7,0.1,1.4,0.1,2.1c0.1,0.7,0.2,1.4,0.3,2
                    c0.4,2,1,3.9,2,5.6c0.9,1.7,2.1,3.1,3.5,4.3c1.4,1.2,3,2,4.9,2.4c1.9,0.5,3.9,0.5,6.1,0.1c7.3-1.4,14-5.2,19.9-11.4
                    c5.9-6.3,11.2-15.4,16-27.5"/>
                    <path className={classes.AnimatedPath8} d="M423.6,95.5c0-0.1,0.5-1.4,1.4-4c0.9-2.6,2.1-5.9,3.7-9.9c1.5-4,3.3-8.5,5.3-13.4c2-4.9,4.1-9.7,6.3-14.4
                    l3.9,2.1c-0.2,0.6-0.4,1.2-0.8,1.8c-0.3,0.7-0.6,1.4-0.9,2.3c-0.3,0.8-0.5,1.8-0.6,2.9c-0.1,1.1,0,2.3,0.2,3.7
                    c0.2,1.2,0.8,2.7,1.8,4.6c1,1.9,2,4.1,3.2,6.6c1.2,2.5,2.3,5.2,3.5,8.1c1.2,2.9,2,6,2.6,9.3c0.9,4.8,1,9.1,0.4,13
                    c-0.6,3.9-1.7,7.3-3.4,10.3c0,0,6.2-2.5,10.6-8.9s6.9-12.4,6.9-12.4l-0.5,6.6c-2.3,4.5-4.2,7-7.2,10.8c-3.1,3.8-6.5,7-10.4,9.8
                    c-3.8,2.7-8.1,4.5-12.8,5.4c-4.2,0.8-7.9,0.4-11.2-1c-3.3-1.5-5.6-4.1-7-8l2.9-1.2c0.1,0.3,0.3,0.9,0.8,1.6c0.4,0.7,1.1,1.4,1.9,2
                    c0.8,0.6,1.8,1.1,3.1,1.4c1.3,0.3,2.7,0.4,4.4,0.1c2.2-0.4,4.1-1.3,5.7-2.7c1.5-1.4,2.8-3,3.8-4.9c1-1.9,1.7-4,2.2-6.4
                    c0.5-2.3,0.8-4.7,0.9-7.1c0.1-2.4,0.1-4.7-0.1-6.9c-0.2-2.2-0.4-4.2-0.8-6c-0.3-1.8-0.8-3.8-1.3-5.9c-0.5-2.2-1.1-4.2-1.6-6.2
                    c-0.5-2-1-3.7-1.4-5.1c-0.4-1.5-0.7-2.4-0.7-2.9l0-0.2c-0.6,0.9-1.2,2.2-2,3.9c-0.7,1.7-1.5,3.6-2.3,5.6c-0.8,2-1.6,4-2.4,6.1
                    c-0.8,2.1-1.5,3.9-2.2,5.6c-0.6,1.7-1.2,3.1-1.6,4.2c-0.4,1.1-0.6,1.7-0.6,1.7"/>
                    <path className={classes.AnimatedPath9} d="M500.5,95.4c-1.4,3.7-2.1,4.8-4.1,8.1c-2,3.3-4.3,6.2-7,8.6s-5.8,4-9.4,4.6c-3.4,0.6-6.1,0.1-8.3-1.6
                    c-2.2-1.7-3.5-4.1-4.1-7.3c-0.7-3.8-0.6-7.8,0.4-12.2c1-4.4,2.4-8.9,4.3-13.4c1.9-4.5,4-8.9,6.3-13.1c2.4-4.3,4.6-8.1,6.6-11.7
                    l2.9,0.7c-0.5,1.6-1.1,3.7-2,6.3c-0.9,2.6-1.8,5.5-2.8,8.6c-1,3.1-2,6.4-3,9.8c-1,3.4-1.8,6.7-2.6,9.9c-0.7,3.2-1.2,6.1-1.5,8.8
                    c-0.3,2.7-0.3,5,0,6.8c0.3,1.6,0.8,2.7,1.6,3.1c0.8,0.5,1.8,0.6,3,0.3c2.3-0.4,4.4-1.6,6.3-3.5s3.7-4.3,5.4-7.1
                    c1.7-2.8,3.3-6,4.8-9.5c1.5-3.5,3.3-7.4,4.7-11 M500.3,36.6c0.2,1.1,0.1,2.3-0.2,3.6c-0.3,1.3-0.8,2.5-1.5,3.6
                    c-0.7,1.1-1.5,2.1-2.5,2.9c-0.9,0.8-2,1.3-3,1.5c-1,0.2-1.7,0-2.4-0.6c-0.6-0.6-1-1.4-1.2-2.4c-0.2-0.9-0.2-2-0.1-3.3
                    c0.1-1.3,0.4-2.6,0.9-3.8c0.5-1.2,1.1-2.3,1.9-3.3c0.8-1,1.8-1.6,3.1-1.8c1.2-0.2,2.3,0,3.3,0.8C499.5,34.6,500.1,35.5,500.3,36.6z"
                    />
                    <path className={classes.AnimatedPath10} d="M546.6,110.8c-1.3,6-3.2,11.8-5,17.8c-5,17.1-10.2,30.1-19.6,42.6c-16,21.3-30.2,15.4-32.9,1.8
                    c-3.2-16.2,2.9-30.4,16.8-41.6c13.9-11.1,39.6-18.7,64.6-7.3c13.4,6.1,31.5,24.8,29.2,45.6c-2,18.5-11.1,23-16.1,23.7
                    c-5.3,0.8-8.5-4-8.7-10.6c-0.3-8.7,5-22.3,17.4-35.6c12-12.9,29.8-23.4,54.8-22.3s43.9,14.2,61.5,26.2c6.7,4.6,15.8,8.6,15.8,8.6
                    s-9.1,9.5-22.9-1.8c-3.7-3-25-23.4-49.5-27.5s-41.5,5.4-54.2,17.1c-10,9.2-19.5,24-18.9,34.3c0.5,9.3,9.2,7,13.5-0.8
                    c3.5-6.2,8.8-20.3-4.6-37.4c-18.3-23.3-59.4-23.7-76.7-12c-17.3,11.7-21,28.7-18.4,41.5c2.1,10.4,13.1,12.9,24.9-6.9
                    c6.6-11.1,8-14.8,13.7-32.3c2.1-6.5,4.1-14.4,5.6-21.2l0.7-2.8c0.5-2.3,1-4.5,1.4-6.4c-2.5,2.5-5.3,4.6-8.1,6.4
                    c-2.9,1.8-5.8,2.9-8.6,3.5c-2.1,0.4-4.3,0.5-6.6,0.3c-2.2-0.2-4.4-0.9-6.4-2c-2-1.1-3.8-2.7-5.3-4.9c-1.5-2.1-2.6-5-3.3-8.4
                    c-0.8-4.2-0.8-8.4,0-12.5c0.8-4.1,2.1-8.1,4-11.9c1.9-3.8,4.2-7.4,7-10.7c2.7-3.3,5.6-6.3,8.7-8.9c3.1-2.6,6.2-4.7,9.5-6.4
                    c3.2-1.7,6.2-2.8,9-3.3c2.7-0.5,5.1-0.7,7.2-0.4c2,0.2,3.8,0.7,5.3,1.3c1.5,0.6,2.7,1.4,3.7,2.2c0.1,0.1,0.2,0.2,0.3,0.3
                    c6.9,6.6,9.9,20,9.9,20l-1.8,0.9c-3.4-9-9.4-16-9.4-16c-0.1-0.1-0.2-0.3-0.3-0.4c-0.6-0.7-1.2-1.2-2-1.7c-0.9-0.6-2.1-1.1-3.4-1.3
                    c-1.3-0.3-2.8-0.3-4.5,0.1c-1.9,0.4-4,1-6.4,2c-2.3,1-4.7,2.3-7.2,4c-2.5,1.7-4.9,3.9-7.3,6.5c-2.4,2.6-4.6,5.7-6.7,9.3
                    c-3,5.3-5.1,10.4-6.3,15.4c-1.2,5-1.5,9.3-0.8,13.1c0.7,3.6,2.1,6.2,4.3,7.8c2.2,1.5,4.9,2,8.1,1.4c3.4-0.6,6.7-2.3,10.1-5.1
                    c3.4-2.8,6.6-6.2,9.7-10.3c1-3.1,2-6.2,2.9-9.3c1-3,2-6.1,3.1-9.2c1.1-3.1,2.3-6.2,3.5-9.5c1.3-3.2,2.7-6.7,4.4-10.2l2.1-5.2
                    l3.4-6.8l3.1,1.3c0,0.1-0.3,1-0.9,2.9c-0.6,1.9-1.3,4.4-2.3,7.5c-0.9,3.1-2,6.8-3.2,11c-1.2,4.2-2.5,8.6-3.8,13.3
                    c-1.3,4.7-2.6,9.6-3.9,14.5l-1.5,6.5L546.6,110.8z"/>
                    <path className={classes.AnimatedPath11} d="M652.9,70.5c-1.3,2.9-2.7,5.9-4.4,9.1c-1.6,3.2-3.5,6.2-5.5,8.9c-2,2.8-4.2,5.2-6.5,7.2s-4.8,3.2-7.4,3.7
                    c-1.5,0.3-2.9,0.3-4.3,0c-1.3-0.3-2.5-0.8-3.5-1.5c-1-0.7-1.9-1.7-2.6-2.8s-1.2-2.3-1.5-3.7c-0.4-2.2-0.4-4.6,0.1-7.4
                    c0.5-2.7,1.2-5.6,2.2-8.5c1-3,2-6,3.3-9c1.2-3,2.3-5.9,3.3-8.7c1-2.7,1.8-5.2,2.4-7.5c0.6-2.3,0.7-4.2,0.5-5.7
                    c-0.3-1.7-1.1-3.1-2.2-4.1s-2.6-1.4-4.2-1c-1.8,0.3-3.7,1.5-5.8,3.4c-2.1,1.9-4.3,4.6-6.7,8c-2.4,3.4-4.9,7.5-7.6,12.3
                    c-2.7,4.8-5.5,10.3-8.6,16.3c-1.7,3.5-3.4,6.9-5.1,10.2c-0.7,1.4-1.4,2.8-2.2,4.3c-0.7,1.5-1.4,2.9-2.1,4.4c-0.7,1.4-1.4,2.8-2,4.1
                    c-0.6,1.3-1.2,2.4-1.7,3.4l-8-1.6l7.3-15.4c1-2.2,2.1-4.5,3.1-6.9c1-2.5,2.1-5,3.1-7.6c1-2.6,1.9-5.2,2.8-7.8
                    c0.8-2.6,1.6-5.1,2.3-7.6c1-3.7,1.6-6.7,1.8-9c0.2-2.3,0.2-4.2-0.1-5.5c-0.3-1.5-0.7-2.5-1.4-2.8c-0.6-0.4-1.5-0.5-2.5-0.3
                    c-1.1,0.2-2.2,1-3.3,2.3c-1.2,1.3-2.3,3-3.5,5c-1.1,2-2.2,4.2-3.3,6.6c-1.1,2.4-2.1,4.8-3,7.2c-0.9,2.4-1.8,4.6-2.7,6.8
                    c-0.8,2.1-1.5,3.8-2.1,5.2l-3.5-1.8c2-5,3.8-9.6,5.5-13.8c1.6-4.2,3.3-7.8,4.9-10.9c1.7-3.1,3.4-5.6,5.1-7.4c1.8-1.9,3.8-3,6.1-3.4
                    c1.2-0.2,2.5-0.2,3.7,0.1c1.2,0.3,2.4,0.8,3.4,1.7c1,0.8,2,1.9,2.7,3.2c0.8,1.3,1.3,2.9,1.7,4.7c0.3,1.5,0.4,3,0.3,4.6
                    c2-2.9,3.9-5.3,5.8-7.2c1.8-2,3.6-3.6,5.2-4.8c1.6-1.3,3.1-2.2,4.6-2.9c1.4-0.7,2.8-1.1,4-1.4c1.1-0.2,2.3-0.2,3.8-0.1
                    c1.5,0.1,2.9,0.6,4.4,1.4c1.4,0.8,2.8,2.1,4,3.8c1.2,1.7,2.1,4.1,2.7,7c0.4,2.4,0.5,4.9,0.1,7.6c-0.4,2.6-1,5.3-1.8,8.1
                    c-0.8,2.7-1.7,5.4-2.7,8.1c-1,2.7-1.9,5.3-2.8,7.7c-0.9,2.5-1.5,4.8-2,7c-0.5,2.2-0.5,4.1-0.2,5.8c0.2,1.3,0.7,2.1,1.3,2.4
                    c0.6,0.3,1.3,0.4,2,0.3c3-0.2,7-0.6,13.2-8.6s10.8-19.5,10.8-19.5"/>
                    <path className={classes.AnimatedPath12} d="M722.3,57.9c3.9-2.2,7.7-4.5,11.4-6.7c4.6-2.7,8.7-5.5,12.1-8.4c3.5-2.9,6.1-5.9,7.9-9
                    c1.8-3.1,2.4-6.5,1.7-10.2c-0.2-1.3-0.8-2.6-1.6-3.8c-0.8-1.2-1.9-2.2-3.2-3.1c-1.3-0.8-2.9-1.4-4.8-1.7c-1.9-0.3-4-0.2-6.4,0.2
                    c-3.2,0.6-6.4,1.8-9.5,3.7c-3.1,1.9-5.7,4.4-7.9,7.6c-2.2,3.2-3.7,7-4.6,11.6c-0.9,4.6-0.8,9.8,0.3,15.7c0.2,0.9,0.4,1.9,0.6,2.8
                    c0.2,1,0.5,2,0.9,3l1.5,3.9c1.3,3.1,2.9,6.3,4.8,9.7c1.9,3.4,4.1,6.8,6.5,10.2c2.4,3.4,5.1,6.7,8,10c2.9,3.3,6,6.3,9.2,9.2l3.6,3.1
                    c3.1,2.5,6.3,4.8,9.6,6.8c3.3,2,6.7,3.6,10.2,4.9c3.5,1.3,6.9,2.1,10.4,2.5c3.5,0.4,7,0.3,10.5-0.4c2.9-0.5,5.6-1.4,8.1-2.7
                    c2.5-1.2,4.6-2.8,6.4-4.7c1.7-1.9,3-4.2,3.7-6.8c0.8-2.6,0.8-5.6,0.2-8.9c-0.5-2.9-1.6-5.4-3.3-7.4c-1.6-2-3.6-3.6-5.9-4.7
                    c-2.3-1.2-4.8-1.9-7.5-2.2c-2.7-0.3-5.4-0.2-8.2,0.3c-1.2,0.2-2.4,0.5-3.5,0.8c-1.1,0.3-2.1,0.7-3.1,1l-1.4-3
                    c2.7-1,5.4-1.8,8.2-2.3c3.6-0.7,7.2-0.8,10.8-0.4c3.6,0.4,6.9,1.3,10,2.8c3,1.4,5.6,3.4,7.8,5.9c2.1,2.5,3.5,5.6,4.2,9.1
                    c0.6,3.2,0.4,6.2-0.5,9c-0.9,2.9-2.5,5.5-4.6,7.8c-2.1,2.3-4.8,4.3-8,6c-3.2,1.6-6.8,2.8-10.8,3.6c-4.6,0.9-9.1,1.2-13.6,1
                    c-4.4-0.2-8.7-0.9-12.9-1.9c-4.2-1.1-8.2-2.5-12.2-4.4c-4-1.9-7.7-4-11.3-6.5l-4-2.9c-3.5-2.6-6.7-5.5-9.8-8.7
                    c-3-3.1-5.8-6.4-8.3-9.8c-2.5-3.4-4.8-6.8-6.8-10.4c-2-3.5-3.7-7.1-5.1-10.6l-1.5-4.3c-0.8-2.6-1.5-5.2-2-7.8
                    c-1.1-5.9-1-11.5,0.3-16.8c1.3-5.2,3.4-9.9,6.3-14c2.9-4.1,6.4-7.4,10.5-10.1c4.1-2.7,8.3-4.5,12.7-5.3c2.8-0.5,5.5-0.6,7.9-0.2
                    c2.5,0.4,4.6,1.1,6.5,2.2c1.9,1.1,3.4,2.5,4.7,4.3c1.2,1.7,2.1,3.7,2.5,5.8c0.6,3,0.4,5.8-0.4,8.4c-0.8,2.6-2.1,5.1-3.9,7.5
                    c-1.8,2.4-4,4.6-6.6,6.8c-2.6,2.2-5.5,4.2-8.6,6.3c-3.1,2-6.4,4-9.8,6c-2.3,1.3-4.6,2.7-6.9,4.1c0,0-6.2,3.3-14.3,8.9
                    c-2.4,1.7-4.6,3.4-6.8,5.2c-2.9,2.4-5.3,4.9-7.2,7.6c-2,2.7-3.4,5.5-4.2,8.5c-0.9,3-1,6.3-0.3,9.7c0.5,2.9,1.5,5.6,2.9,7.9
                    c1.4,2.3,3.3,4.2,5.7,5.7c2.4,1.5,5.3,2.5,8.6,2.9c3.4,0.4,7.3,0.3,11.7-0.6c3.9-0.7,7.8-1.9,11.5-3.5c2.6-1.1,5.2-2.3,7.6-3.8
                    c6.4-4.2,13-10.3,13-10.3c1.7-1.7,3.3-3.6,4.8-5.5c2.3-3,4.1-6.1,5.6-9.2c1.5-3.2,2.5-6.4,3.1-9.6c0.6-3.2,0.6-6.3,0-9.4
                    c-0.4-2.3-1-4.1-1.6-5.4c-0.7-1.3-1.1-2.2-1.2-2.6l4.2-2c0.3,0.6,0.9,1.7,1.6,3.3c0.7,1.6,1.2,3.6,1.7,5.9c0.6,3.2,0.6,6.5,0.1,10
                    c-0.5,3.5-1.6,7-3.1,10.5c-1.5,3.5-3.5,6.9-6,10.3c-1.8,2.5-3.8,4.8-6,7.1c-5.9,6.2-12.6,10.1-12.6,10.1c-2.8,1.8-5.8,3.3-8.9,4.7
                    c-4.4,2-9.2,3.4-14.2,4.4c-6.2,1.1-11.6,1.5-16.2,1c-4.6-0.5-8.5-1.6-11.7-3.3c-3.2-1.7-5.7-3.9-7.5-6.6c-1.8-2.7-3.1-5.7-3.7-9.1
                    c-0.8-4.3-0.5-8.4,0.8-12.1c1.4-3.7,3.5-7.2,6.3-10.5c2.9-3.2,6.3-6.3,10.3-9.2c3.3-2.4,6.7-4.6,10.2-6.9
                    C708.4,66,714.4,62.5,722.3,57.9z"/>
                    <path className={classes.AnimatedPath13} d="M320.4,272.4 M359,283.1c0,0-16.6,6.6-28.6,2.8s-15.6-17.7-15.6-17.7c-0.6-3-0.8-5.5-0.6-7.7
                    c0.2-2.1,0.5-3.9,1.1-5.3c0.5-1.4,1.2-2.5,2.1-3.2c0.8-0.7,1.6-1.2,2.5-1.3c0.7-0.1,1.4-0.1,2.1,0.1c0.7,0.2,1.4,0.6,2,1.2
                    c0.6,0.6,1.2,1.5,1.8,2.6c0.5,1.1,1,2.5,1.3,4.3c0.8,4,0.7,8.3-0.1,12.9c-0.8,4.6-2.2,9.1-4.1,13.5c-1.9,4.5-4.3,8.8-7.1,13
                    c-2.8,4.2-5.8,8-9,11.3c-0.9,0.9-1.9,1.9-3.1,3c-1.2,1.1-2.4,2.1-3.8,3.1c-1.4,1-2.8,1.9-4.3,2.6c-1.5,0.8-3,1.3-4.5,1.6
                    c-3.8,0.7-7.1-0.1-10-2.5c-2.9-2.4-4.8-6.1-5.8-11.2c-0.4-2.4-0.6-4.9-0.4-7.7c0.2-2.8,0.6-5.7,1.2-8.8c0.6-3,1.5-6.1,2.5-9.3
                    c1-3.1,2.2-6.2,3.5-9.1s4.1-8.4,4.1-8.4c-11.1-1.2-17.2-11.1-17.2-11.1l3.2-4.7c7.6,11.7,18.4,10.2,22.6,10.5c0,0-4.2,10.8-5.7,15.9
                    c-1.5,5.1-2.6,9.8-3.4,14.2c-0.8,4.4-1.2,8.3-1.4,11.9c-0.1,3.5,0,6.5,0.5,9c0.5,2.9,1.3,4.8,2.3,5.9c1,1.1,2.2,1.5,3.6,1.2
                    c1.3-0.3,3.1-1.1,5.3-2.5c2.2-1.4,4.7-3.5,7.4-6.3c3.5-3.5,6.6-7.6,9.5-12.1c2.9-4.5,5.2-9.2,7-14l1.9-4.7c0.7-2.7,1-5.5,1.2-8.3
                    c0.1-2.8,0-5.5-0.5-7.9c-0.3-1.7-0.7-2.9-1.1-3.5c-0.4-0.7-0.7-1.1-0.9-1.1c-0.1,0-0.3,0.2-0.6,0.6c-0.3,0.4-0.7,1.1-1,2
                    c-0.3,1-0.5,2.2-0.6,3.8c-0.1,1.6,0.1,3.6,0.6,6c0.9,5,4.1,15,14.9,15.5s16.8,0,23.5-3.4"/>
                    <path className={classes.AnimatedPath14} d="M442.9,272.5c-1.6,2.9-6.2,10.2-8.2,12.8c-2,2.6-4.2,4.8-6.6,6.7c-2.4,1.9-5,3.1-7.7,3.6
                    c-2.3,0.4-4.6,0.4-6.8-0.1c-2.3-0.5-4.4-1.5-6.3-3.1c-1.9-1.6-3.7-3.7-5.2-6.5c-1.5-2.7-2.7-6.1-3.4-10.2
                    c-0.9-5.1-1.3-10.5-1.2-16.3c0.2-5.8,0.8-11.7,1.8-17.7c1-6,2.7-11.9,4.2-18c4.3-17.3,8.8-30.5,17.6-43.5c15-22,29.4-15.4,30.6-1.5
                    c1.5,16.9-6.5,35.4-19.8,47.1s-31.5,13.9-57,3.6c-13.6-5.5-32.6-23.4-31.2-44.2c1.2-18.5,10-23.4,15-24.4c5.2-1,8.6,3.6,9.1,10.2
                    c0.7,8.7-4,22.5-15.8,36.4c-11.4,13.4-28.8,24.8-53.8,24.8s-44.5-12.2-62.6-23.4c-6.9-4.2-16.2-7.9-16.2-7.9s8.7-9.9,22.9,0.8
                    c3.8,2.9,26,22.3,50.7,25.3c24.7,3,41.2-7.2,53.4-19.5c9.5-9.6,18.4-24.9,17.4-35.1c-0.9-9.2-9.5-6.6-13.5,1.4
                    c-3.2,6.4-7.9,20.7,6.2,37.1c19.3,22.4,53.3,27.6,70,15.1s23.1-38,21-48.4c-2.1-10.4-11-12.8-21.9,7.6c-6.1,11.4-7.3,15.2-12.2,32.9
                    c-1.8,6.6-3.4,14.6-4.7,21.4c-1.3,6.8-1.9,13.4-2,19.8c-0.2,2.7-0.1,7.8-0.1,7.8c-0.1,2.2,0.1,4.3,0.5,6.2c0.4,1.9,0.7,3.7,1,5.3
                    c0.9,4.9,2.4,8.3,4.4,10.4c2,2.1,4.4,2.8,7.3,2.3c1.9-0.4,3.7-1.2,5.5-2.5c1.7-1.3,3.4-3,5-4.9c1.6-1.9,3.1-4,4.6-6.4
                    c1.4-2.3,2.7-4.7,4-7c1.2-2.3,2.3-4.6,3.2-6.7c0.9-2.1,1.7-4,2.4-5.6"/>
                    <path className={classes.AnimatedPath15} d="M503.8,257.7c-2.6,2-5.4,3.7-8.7,4c-6.1,0.6-8.1-0.3-12.2-3.7c-1.6-1.3-2.9-3-4.1-4.9c-1.2-1.9-2.3-4-3.1-6.2
                    c-0.9-2.3-1.5-4.6-2-7.1c-0.5-2.5-0.6-4.8-0.4-7.1c0.2-2.3,0.7-4.4,1.5-6.2c0.8-1.9,1.8-3.4,3.1-4.6c1.3-1.2,2.8-2,4.5-2.3
                    c1.6-0.3,3-0.1,4.4,0.4c1.4,0.6,2.6,1.5,3.7,2.8c1.1,1.3,2.1,2.8,2.9,4.7c0.8,1.9,1.4,3.9,1.8,6.2c0.3,1.7,0.5,3.6,0.6,5.6
                    c0.1,2.1,0.1,4.2-0.1,6.3c-0.2,2.1-0.4,4.3-0.8,6.4c-0.4,2.1-0.9,4.1-1.7,5.8l-1.4,4.2c-1.7,4.5-3.6,8.4-5.8,11.7
                    c-2.2,3.3-4.6,6.2-7.1,8.5c-2.5,2.3-5.1,4.2-7.8,5.5c-2.7,1.3-5.2,2.2-7.6,2.7c-2.5,0.5-4.8,0.4-7.1,0c-2.3-0.5-4.3-1.4-6.1-2.7
                    c-1.8-1.3-3.4-3.1-4.6-5.1c-1.3-2.1-2.1-4.5-2.7-7.3c-0.5-2.8-0.6-5.7-0.3-8.8c0.4-3.1,1-6.2,2-9.3c1-3.1,2.3-6.2,3.9-9.3
                    c1.6-3,3.4-5.9,5.3-8.7c2-2.7,4.1-5.3,6.3-7.6c2.2-2.3,4.5-4.3,6.9-6l3.3,3c-2.6,2.7-5.1,5.9-7.5,9.7c-2.4,3.8-4.6,7.9-6.3,12.2
                    c-1.8,4.3-3.1,8.7-3.9,13.2c-0.8,4.5-0.9,8.7-0.1,12.6c0.7,3.5,1.9,6.1,3.9,7.8c1.9,1.6,4.5,2.2,7.7,1.6c2.5-0.5,4.8-1.4,7-2.9
                    c2.2-1.5,4.3-3.3,6.3-5.4c2-2.2,3.8-4.6,5.5-7.4c1.7-2.8,3.2-5.7,4.6-8.7l1.4-4c0.6-2.1,1.1-4.3,1.4-6.5c0.3-2.2,0.5-4.3,0.7-6.3
                    c0.1-2,0.1-3.8,0-5.5c-0.1-1.7-0.3-3.1-0.5-4.4c-0.3-1.7-0.7-3.2-1.3-4.5c-0.5-1.3-1.1-2.5-1.8-3.4c-0.7-0.9-1.4-1.6-2.2-2.1
                    c-0.8-0.5-1.6-0.6-2.5-0.5c-1.1,0.2-2.1,0.8-2.9,1.7c-0.8,0.9-1.4,2.1-1.9,3.5c-0.4,1.4-0.7,2.9-0.7,4.7c-0.1,1.7,0.1,3.5,0.4,5.3
                    c0.3,1.6,0.8,3.4,1.4,5.2c0.6,1.9,1.4,3.6,2.4,5.3c0.9,1.7,2,3.2,3.3,4.6c2.8,3,5.5,4,9.7,3.5c1.6-0.2,3-0.7,4.2-1.3
                    c1.2-0.5,2.3-1.3,3.3-2.1c1-0.9,2.4-2.1,3.2-3.4c0.8-1.3,2.9-5.3,3.6-7.1"/>
                    <path className={classes.AnimatedPath16} d="M579,235.5c-0.4,1.3-1,3.1-1.8,5.4c-0.8,2.2-1.8,4.7-3,7.3c-1.2,2.6-2.5,5.3-4,8c-1.5,2.7-3.1,5.2-4.9,7.5
                    c-1.8,2.3-3.7,4.3-5.6,5.9c-2,1.6-4,2.6-6.2,3c-1.5,0.3-2.9,0.3-4.3,0.1c-1.4-0.2-2.7-0.7-3.9-1.4c-1.2-0.7-2.2-1.8-3.1-3.1
                    c-0.9-1.3-1.5-3-1.9-5c-0.4-2.4-0.5-5.3-0.1-8.6c0.4-3.3,1.6-7.3,3.7-11.8c1.7-3.7,3.2-6.9,4.4-9.4c1.3-2.5,2.3-4.7,3.1-6.6
                    c0.8-1.9,1.3-3.5,1.5-5c0.2-1.5,0.2-3-0.1-4.7c-0.3-1.5-0.9-2.5-1.9-3.1c-1-0.6-2-0.8-3.1-0.6c-2.9,0.5-5.6,1.9-8.4,4.1
                    c-2.7,2.2-5.3,4.9-7.8,8.1c-2.5,3.2-4.8,6.8-7,10.7c-2.2,3.9-4.3,7.9-6.2,12c-1.9,4-3.6,8-5.1,11.8c-1.5,3.8-2.8,7.2-3.9,10.2
                    c-1.1,3-1.9,5.4-2.4,7.2c-0.6,1.8-0.9,2.8-0.9,2.8c-1.5,5.3-2.9,10.3-4.3,15.3c-1.4,4.9-2.5,9.6-4,14c-2.2,6.7-4.3,10.9-12.5,13.4
                    s-18.4,0.3-19.6-3.9c-1.7-5.8,8.9-15.9,33.2-4.9c24.3,11,33.6,21.6,75.6,30c42.6,8.5,89.5,1.4,128.9-16.9
                    c44.7-20.7,23.8-42.7-21.1-39.1s-87,34.3-133.6,59.2c-46.6,24.9-77.4,26.7-118.2,11.5c-40.8-15.2-50.2-17.1-62-17.7
                    c-10.1-0.5-17.3,0.9-17.3,0.9c1.4-4.8,14.3-4.9,31.8-0.8c14.8,3.5,32.9,10.3,48.8,16.1c60.9,22,116.5-9.9,172.7-45.5
                    c27.3-17.3,46.7-26,62.8-30.2c15.5-4,28.9-5.6,42-4.5c5.8,0.5,11.7,2.2,16,5.3c7.9,5.9,11.5,15.6,7.6,23.5
                    c-10.8,21.8-54.6,38.9-109.4,42.9c-57.9,4.2-102.2-21.5-126.5-33c-11.2-5.3-19.5-5.1-24.3-3.6s-8.3,7.2-1.5,8.5s13.5-2.6,15.8-10.3
                    c2.4-7.7,4.7-14.9,6.9-21.8c2.2-6.8,4.4-13.4,6.6-19.9c2.2-6.4,4.5-13.2,6.9-20.2c2.5-7,5.1-14.6,8.1-22.7
                    c2.9-8.1,6.3-17.3,10.1-27.5l4.8-1l-7.8,28.5l-5.8,15c2.6-4.9,5.3-9.6,8.3-14c3-4.4,6.2-8.2,9.6-11.2c2.5-2.2,4.8-3.8,6.9-4.9
                    c2.1-1,4.1-1.8,6-2.1c1.9-0.4,3.5-0.3,4.9,0c1.3,0.4,2.5,1,3.4,1.9c0.9,0.9,1.6,1.9,2.1,3c0.5,1.2,0.9,2.3,1.1,3.5
                    c0.5,2.6,0.5,5.3,0.1,8.1c-0.4,2.7-1,5.5-1.9,8.3c-0.8,2.8-1.8,5.5-2.9,8.2c-1.1,2.7-2.1,5.4-3,7.9c-0.9,2.6-1.6,5.1-2,7.5
                    c-0.5,2.4-0.5,4.6-0.1,6.7c0.2,1,0.5,1.8,0.8,2.4c0.4,0.6,0.8,1,1.3,1.3c0.5,0.3,1,0.4,1.5,0.5c0.5,0,1,0,1.5-0.1
                    c1.1-0.2,2.2-0.9,3.5-2.2c1.2-1.2,2.5-2.8,3.9-4.7c1.3-1.9,2.7-4,4-6.4c1.3-2.4,2.6-4.8,3.8-7.2c1.2-2.5,2.3-4.9,3.3-7.2
                    c1-2.4,1.9-4.5,2.6-6.4"/>
                    <path className={classes.AnimatedPath17} d="M575.4,233.7c2.1-5.3,4-9.9,5.7-13.9c1.7-4,3.4-7.4,5.1-10.2c1.7-2.8,3.5-5,5.3-6.6c1.9-1.6,4-2.7,6.5-3.1
                    c0.7-0.1,1.7-0.2,2.9-0.1c1.2,0.1,2.4,0.4,3.7,0.9c1.3,0.5,2.4,1.3,3.5,2.5c1.1,1.1,1.9,2.7,2.5,4.7c2.4-4.6,5.1-8.4,8-11.5
                    c3-3,6-4.8,9-5.4c2.2-0.4,4.1-0.3,5.8,0.3c1.7,0.6,3.1,1.5,4.3,2.7c1.2,1.2,2.2,2.7,2.9,4.4c0.7,1.7,1.3,3.4,1.6,5.2
                    c0.1,0.4,0.2,0.9,0.2,1.4c0.1,0.5,0.1,1,0.2,1.5c1.3-2.2,2.6-4.3,4.1-6.2c1.4-1.9,2.9-3.7,4.4-5.2c1.5-1.5,3.1-2.7,4.7-3.7
                    c1.6-1,3.2-1.6,4.7-1.9c1.2-0.2,2.5-0.2,3.8,0c1.3,0.2,2.5,0.6,3.6,1.3c1.1,0.7,2.1,1.7,2.9,2.9c0.8,1.2,1.4,2.8,1.7,4.6
                    c0.5,2.7,0.6,5.4,0.4,8.1c-0.2,2.7-0.7,5.4-1.4,8.1c-0.7,2.7-1.4,5.3-2.2,7.8c-0.8,2.5-1.6,5-2.3,7.3c-0.7,2.3-1.3,4.5-1.7,6.5
                    c-0.4,2-0.4,3.8-0.1,5.4c0.2,1.2,0.7,2.1,1.5,2.5c0.7,0.4,1.5,0.5,2.2,0.4c2-0.2,6-1.8,10.5-9.2c4.4-7.4,9-20.8,9-20.8l-0.5,11.3
                    c-1.1,2.5-3.3,6.1-4.6,8.7c-1.4,2.6-2.9,5-4.5,7.2c-1.6,2.2-3.4,4.1-5.3,5.6c-1.9,1.5-3.9,2.5-6.1,2.9c-1.2,0.2-2.3,0.3-3.5,0.1
                    c-1.2-0.1-2.2-0.5-3.2-1.1c-1-0.6-1.8-1.5-2.6-2.6c-0.7-1.1-1.3-2.6-1.6-4.4c-0.5-2.5-0.5-5-0.1-7.5c0.4-2.5,1-5,1.9-7.6
                    c0.9-2.5,1.8-5.1,2.9-7.6c1.1-2.5,2-5.1,2.9-7.5c0.9-2.5,1.5-4.9,1.9-7.4c0.4-2.4,0.4-4.8,0-7.2c-0.2-1.2-0.6-2.1-1.2-2.7
                    c-0.6-0.6-1.5-0.8-2.7-0.6c-2,0.4-3.9,1.5-5.8,3.6c-1.9,2-3.8,4.6-5.6,7.7c-1.9,3.1-3.7,6.7-5.5,10.7c-1.8,4-3.6,8.2-5.3,12.6
                    c-1.7,4.4-3.4,8.9-5,13.4c-1.6,4.5-3.2,8.8-4.7,13l-7.7-2.4c1.8-6.7,3.6-12.6,5.3-17.9c1.7-5.3,3.2-10.1,4.4-14.3
                    c1.2-4.3,2.1-8.1,2.6-11.5c0.5-3.4,0.5-6.6,0-9.4c-0.1-0.6-0.3-1.1-0.5-1.7c-0.2-0.6-0.5-1-1-1.4c-0.4-0.4-0.9-0.7-1.5-0.9
                    c-0.6-0.2-1.3-0.2-2.1-0.1c-1.9,0.4-3.7,1.6-5.3,3.7c-1.6,2.1-3.3,4.9-4.9,8.4c-1.6,3.5-3.3,7.5-5,12c-1.7,4.5-3.6,9.3-5.7,14.4
                    c-2,5.1-4.3,10.3-6.8,15.7c-2.5,5.4-5.3,10.7-8.4,16l-6.5-2.8c1.3-3,2.7-6.2,4.1-9.6c1.4-3.4,2.8-6.8,4.2-10.2
                    c1.4-3.4,2.6-6.9,3.8-10.2c1.2-3.4,2.2-6.6,3-9.6c0.8-3,1.4-5.7,1.8-8.2c0.4-2.4,0.4-4.5,0.1-6.1c-0.4-1.9-1.1-3.2-2.2-3.8
                    c-1.1-0.7-2.4-0.9-3.9-0.6c-1.8,0.3-3.4,1.3-4.9,2.9c-1.5,1.6-2.9,3.8-4.4,6.5c-1.4,2.7-2.9,5.9-4.5,9.6c-1.5,3.7-3.3,7.7-5.3,12.2"
                    />
                    <path className={classes.AnimatedPath18} d="M749.5,201.3c2-5,3.8-9.6,5.5-13.8c1.6-4.2,3.3-7.8,4.9-10.9c1.7-3.1,3.4-5.6,5.1-7.4c1.8-1.9,3.8-3,6.1-3.4
                    c1.2-0.2,2.5-0.2,3.7,0.1c1.2,0.3,2.4,0.8,3.4,1.7c1,0.8,2,1.9,2.7,3.2c0.8,1.3,1.3,2.9,1.7,4.7c0.3,1.5,0.4,3,0.3,4.6
                    c2-2.9,3.9-5.3,5.8-7.2c1.8-2,3.6-3.6,5.2-4.8c1.6-1.3,3.1-2.2,4.6-2.9c1.4-0.7,2.8-1.1,4-1.4c1.1-0.2,2.3-0.2,3.8-0.1
                    c1.5,0.1,2.9,0.6,4.4,1.4c1.4,0.8,2.8,2.1,4,3.8c1.2,1.7,2.1,4.1,2.7,7c0.4,2.4,0.5,4.9,0.1,7.6c-0.4,2.6-1,5.3-1.8,8.1
                    c-0.8,2.7-1.7,5.4-2.7,8.1c-1,2.7-1.9,5.3-2.8,7.7c-0.9,2.5-1.5,4.8-2,7c-0.5,2.2-0.5,4.1-0.2,5.8c0.2,1.3,0.7,2.1,1.3,2.4
                    c0.6,0.3,1.3,0.5,2,0.3c2.1-0.4,4.3-1.9,6.6-4.5c2.2-2.6,4.4-5.7,6.5-9.4c2.1-3.7,4.2-7.7,6.2-11.9c2-4.3,4.3-9.2,5.9-13l-0.4,12.1
                    c-1.3,2.9-4.1,8.6-5.8,11.8c-1.6,3.2-3.5,6.2-5.5,8.9c-2,2.8-4.2,5.2-6.5,7.2s-4.8,3.2-7.4,3.7c-1.5,0.3-2.9,0.3-4.3,0
                    c-1.3-0.3-2.5-0.8-3.5-1.5c-1-0.7-1.9-1.7-2.6-2.8c-0.7-1.1-1.2-2.3-1.5-3.7c-0.4-2.2-0.4-4.6,0.1-7.4c0.5-2.7,1.2-5.6,2.2-8.5
                    c1-3,2-6,3.3-9c1.2-3,2.3-5.9,3.3-8.7c1-2.7,1.8-5.2,2.4-7.5c0.6-2.3,0.7-4.2,0.5-5.7c-0.3-1.7-1.1-3.1-2.2-4.1
                    c-1.1-1-2.6-1.4-4.2-1c-1.8,0.3-3.7,1.5-5.8,3.4c-2.1,1.9-4.3,4.6-6.7,8c-2.4,3.4-4.9,7.5-7.6,12.3c-2.7,4.8-5.5,10.3-8.6,16.3
                    c-1.7,3.5-3.4,6.9-5.1,10.2c-0.7,1.4-1.4,2.8-2.2,4.3c-0.7,1.5-1.4,2.9-2.1,4.4c-0.7,1.4-1.4,2.8-2,4.1c-0.6,1.3-1.2,2.4-1.7,3.4
                    l-8-1.6l7.3-15.4c1-2.2,2.1-4.5,3.1-6.9c1-2.5,2.1-5,3.1-7.6c1-2.6,1.9-5.2,2.8-7.8c0.8-2.6,1.6-5.1,2.3-7.6c1-3.7,1.6-6.7,1.8-9
                    c0.2-2.3,0.2-4.2-0.1-5.5c-0.3-1.5-0.7-2.5-1.4-2.8c-0.6-0.4-1.5-0.5-2.5-0.3c-1.1,0.2-2.2,1-3.3,2.3c-1.2,1.3-2.3,3-3.5,5
                    c-1.1,2-2.2,4.2-3.3,6.6c-1.1,2.4-2.1,4.8-3,7.2s-1.8,4.6-2.7,6.8c-0.8,2.1-1.5,3.8-2.1,5.2"/>
                    <path className={classes.AnimatedPath19} d="M855.3,146.2c5.5-2.5,14.2-9.5,17.9-12.7c10.2-8.7,16.4-22,11.8-36.7c-4.6-14.7-19.8,3.9-24.6,12.6
                    s-6.1,12.4-9.5,20.5c-2.3,5.4-4.5,12.1-5.8,15.9l-0.2,0.6c-7.1,2.4-14.8,4.4-22.6,6.1c0,0,8.7,0.5,20.7-0.6l-0.4,1.5
                    c-1.5,4.9-2.7,9.6-3.6,13.9c-1.2,5.7-2,10.7-2.5,15.2c-0.5,4.5-0.7,8.3-0.7,11.5c0,3.2,0.2,5.6,0.5,7.3c0.5,2.6,1.2,4.9,2.3,7
                    c1.1,2.1,2.4,3.8,4,5.1c1.6,1.4,3.4,2.3,5.4,2.9c2,0.5,4.3,0.6,6.8,0.1c4.1-0.8,7.7-2.5,10.9-5.2c3.1-2.7,5.9-5.9,8.3-9.6
                    c2.4-3.7,4.5-7.5,6.3-11.6c1.8-4.1,3.4-8,4.7-11.6l-3.5-1.8c-0.2,0.4-0.6,1.5-1.3,3.3c-0.7,1.8-1.6,3.9-2.8,6.3
                    c-1.1,2.4-2.5,5-4,7.8c-1.5,2.8-3.2,5.4-5.1,7.8c-1.8,2.4-3.8,4.5-5.8,6.3c-2.1,1.7-4.2,2.8-6.4,3.2c-2.5,0.5-4.7,0-6.7-1.2
                    c-2-1.3-3.3-3.7-4-7.4c-0.5-2.9-0.7-6.4-0.5-10.6c0.2-4.1,0.7-8.6,1.6-13.5s1.9-10,3.2-15.4c0.9-3.9,2-7.9,3.1-11.9l0.2-0.7
                    c7.6-1.2,26.9-4.1,38.1-6.1l-0.6-1.8c0,0-28.9,4.9-37.4,5.1l1.2-3.7c1.6-5.2,4.4-12.3,6.1-17.2c1.7-4.9,4.7-11.2,6.4-15.7
                    c2.6-5.7,7.6-18.7,13.1-17.4c1.6,0.4,5.6,7.8,3.6,16.5s-8.7,16.8-13.7,20.8s-7,5.4-12.5,7.9"/>
                    <g>
                        <path fill="#231F20" d="M48.5,134.8c-1.8,0.2-3.2,0.5-4.1,0.9c-0.9,0.4-1.3,1.2-1.3,2.4c0,0.7,0.1,1.3,0.3,2c0.2,0.7,0.5,1.5,0.8,2.4
                            l8.4,21.8l8.2-19.9c0.4-0.9,0.8-2,1.2-3.4c0.4-1.4,0.6-2.4,0.6-3.1c0-1.1-0.4-1.9-1.1-2.3c-0.7-0.4-1.7-0.7-3.1-0.9v-1.3h11.7v1.3
                            c-0.8,0.2-1.4,0.5-1.9,1.1s-1.2,2-2.1,4.1l-14.7,36.3c-0.1,0.3-0.2,0.5-0.3,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.4-0.2-0.6-0.4-0.6-0.5
                            l-11.2-27.8l-11.2,27.7c-0.1,0.2-0.1,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.4-0.2-0.6-0.3-0.6-0.5l-13.7-33.9
                            c-1.5-3.8-2.5-6-3-6.6s-1.5-1.1-2.9-1.3v-1.3h17.1v1.3c-1.4,0.1-2.5,0.4-3.2,0.8c-0.8,0.4-1.1,1.2-1.1,2.5c0,0.9,0.6,3.1,1.9,6.6
                            c0.7,2,1.7,4.3,2.8,7.2l5.3,12.9l8.1-17.4l0.1-0.5c0-0.6-0.3-1.8-1-3.4c-0.7-1.7-1.3-3.2-1.9-4.5c-0.6-1.4-1.3-2.4-2.1-2.9
                            s-1.9-0.9-3.3-1v-1.3h19.2V134.8z"/>
                        <path fill="#231F20" d="M105.7,137.6c3.2,3,4.9,7.2,4.9,12.7H81.3c0.3,7.1,1.9,12.3,4.8,15.5s6.4,4.9,10.3,4.9c3.2,0,5.9-0.8,8.1-2.5
                            c2.2-1.7,4.2-4.1,6.1-7.2l1.6,0.6c-1.3,3.9-3.6,7.5-7.1,10.8c-3.4,3.3-7.7,5-12.7,5c-5.8,0-10.2-2.2-13.4-6.5
                            c-3.1-4.4-4.7-9.4-4.7-15c0-6.2,1.8-11.5,5.5-16c3.6-4.5,8.4-6.7,14.3-6.7C98.6,133.1,102.4,134.6,105.7,137.6z M83.6,141.1
                            c-1,1.7-1.7,3.7-2.1,6.3h19.4c-0.3-3.1-0.9-5.3-1.7-6.9c-1.5-2.7-4-4-7.5-4C88.1,136.5,85.5,138,83.6,141.1z"/>
                        <path fill="#231F20" d="M114.9,117.7v-1.6c3.5-0.8,6.7-1.7,9.5-2.7c2.8-1,4.3-1.5,4.5-1.5c0.1,0.2,0.1,0.4,0.1,0.6v28
                            c0.8-1.5,2-2.9,3.6-4.2c2.8-2.3,6.1-3.4,9.7-3.4c4.5,0,8.3,1.9,11.5,5.7c3.2,3.8,4.8,8.7,4.8,14.8c0,6.5-2.1,12.1-6.2,16.7
                            s-9.6,6.9-16.3,6.9c-3.4,0-6.7-0.7-10-2c-3.3-1.3-4.9-2.7-4.9-4v-48.6c0-1.8-0.2-3-0.7-3.8c-0.5-0.8-1.6-1.1-3.4-1.1L114.9,117.7z
                            M132.4,172.9c2.1,0.7,4,1,5.5,1c4.3,0,7.4-1.6,9.5-4.8c2.1-3.2,3.1-7.1,3.1-11.7c0-4.6-1-8.8-2.9-12.6c-1.9-3.9-5-5.8-9.3-5.8
                            c-2.2,0-4.3,0.7-6.4,2c-2.1,1.3-3.1,3-3.1,5.1v23.4C129.1,171.1,130.2,172.2,132.4,172.9z"/>
                    </g>
            </svg>
        </Fragment>
    )
}

export default introSvg;